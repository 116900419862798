<template>
  <v-row no-gutters class="fill-height">
    <v-col>
      <v-data-table
        :headers="headers"
        :items="medicos"
        class="elevation-1"
        :search="busqueda"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Médicos</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="busqueda"
              label="Busqueda"
              outlined
              dense
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="nuevo()"
              v-if="!esAdministrador"
            >
              Nuevo
            </v-btn>
            <v-dialog v-model="dialog" max-width="800px" persistent>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ tituloDialog }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-form ref="form" v-model="validForm">
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="nombre"
                            label="Nombre"
                            outlined
                            :rules="requerido"
                            :readonly="esAdministrador"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-autocomplete
                            v-model="id_consultorio"
                            :items="items_consultorio"
                            item-value="id_consultorio"
                            item-text="nombre"
                            label="Nombre consultorio"
                            outlined
                            :rules="requerido"
                            :readonly="esAdministrador"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="correo"
                            label="Correo"
                            outlined
                            :rules="requeridoCorreo"
                            :readonly="esAdministrador"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-checkbox
                            v-model="condicion"
                            label="Estado"
                            :readonly="esAdministrador"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="cerrarDialog()">
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    :disabled="!validForm"
                    text
                    @click="guardarRegistro()"
                    v-if="flag === -1 && !esAdministrador"
                  >
                    Guardar
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    :disabled="!validForm"
                    text
                    @click="actualizarRegistro()"
                    v-if="flag === 1 && !esAdministrador"
                  >
                    Actualizar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogEliminar" max-width="500px" persistent>
              <v-card>
                <v-card-title class="text-h5"
                  >Esta seguro de eliminar este registro?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogEliminar = false"
                    >Cancelar</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="eliminarRegistro()"
                    >Si, eliminar</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                @click="editarItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.condicion`]="{ item }">
          <v-chip :color="colorEstado(item.condicion)" dark>
            <div v-if="item.condicion"><span>Activo</span></div>
            <div v-else><span>Inactivo</span></div>
          </v-chip>
        </template>
      </v-data-table>
      <v-snackbar
        v-model="mensaje"
        timeout="5000"
        :color="mensajeColor"
        elevation="24"
        >{{ mensajeTexto }}</v-snackbar
      >
      <v-overlay v-model="overlay">
        <lottie-player
          autoplay
          loop
          mode="normal"
          src="https://assets6.lottiefiles.com/packages/lf20_uwR49r.json"
          style="width: 200px"
        >
        </lottie-player>
      </v-overlay>
    </v-col>
  </v-row>
</template>
<script>
import axios from "axios";
import "@lottiefiles/lottie-player";

export default {
  data: () => ({
    busqueda: "",
    dialog: false,
    dialogEliminar: false,
    headers: [
      {
        text: "Nombre",
        value: "nombre",
      },
      { text: "Correo", value: "correo" },
      { text: "Consultorio", value: "nombre_consultorio" },
      { text: "Estado", value: "condicion", align: "center" },
      { text: "Opciones", value: "actions", sortable: false },
    ],
    medicos: [],
    id_medico: 0,
    nombre: "",
    correo: "",
    id_consultorio: "",
    items_consultorio: [],
    condicion: false,
    turno: false,
    //variables varios
    validForm: false,
    flag: -1,
    requerido: [(v) => !!v || "El campo es obligatorio"],
    requeridoCorreo: [
      (v) => !!v || "Correo es obligatorio",
      (v) => /.+@.+\..+/.test(v) || "Formato de correo es invalido",
    ],
    mensaje: false,
    mensajeTexto: "",
    mensajeColor: "primary",
    overlay: false,
  }),
  computed: {
    tituloDialog() {
      return this.flag === -1 ? "Nuevo Registro" : "Editar Registro";
    },
    esAdministrador() {
      if (this.$store.state.usuario) {
        if (this.$store.state.usuario.rol == "Administrador") {
          return false;
        } else {
          return true;
        }
      }
    },
  },
  created() {
    this.superUsuario();
    this.listarConsultorios();
  },
  methods: {
    superUsuario() {
      if (this.$store.state.usuario.super_usuario == "True") {
        this.listarMedicosSuperUsuario();
      } else {
        this.listarMedicos();
      }
    },
    listarMedicosSuperUsuario() {
      this.overlay = true;
      axios
        .get("api/Medicos/Listar")
        .then((response) => {
          this.overlay = false;
          this.medicos = response.data;
        })
        .catch((error) => {
          this.overlay = false;
          console.log(error);
          this.alerta("Sucedio algún error", "error");
        });
    },
    listarMedicos() {
      this.overlay = true;
      axios
        .get("api/Medicos/Listar")
        .then((response) => {
          this.overlay = false;
          response.data.forEach((element) => {
            if (
              this.$store.state.usuario.id_consultorio == element.id_consultorio
            ) {
              this.medicos.push(element);
            }
          });
        })
        .catch((error) => {
          this.overlay = false;
          console.log(error);
          this.alerta("Sucedio algún error", "error");
        });
    },
    listarConsultorios() {
      axios
        .get("api/Consultorios/Listar/" + this.$store.state.usuario.idUsuario)
        .then((response) => {
          this.items_consultorio = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.alerta("Sucedio algún error", "error");
        });
    },
    nuevo() {
      this.flag = -1;
      this.dialog = true;
    },
    cerrarDialog() {
      this.dialog = false;
      this.$refs.form.reset();
    },
    guardarRegistro() {
      this.overlay = true;
      axios
        .post("api/Medicos/Crear", {
          id_consultorio: this.id_consultorio,
          nombre: this.nombre,
          correo: this.correo,
          condicion: this.condicion,
        })
        .then(() => {
          this.dialog = false;
          this.overlay = false;
          this.$refs.form.reset();
          this.limpiarVariables();
          this.superUsuario();
          this.alerta("Registro creado con éxito", "success");
        })
        .catch((error) => {
          this.overlay = false;
          console.log(error);
          this.alerta("Sucedio algún error", "error");
        });
    },
    editarItem(item) {
      this.flag = 1;
      this.id_medico = item.id_medico;
      this.id_consultorio = item.id_consultorio;
      this.nombre = item.nombre;
      this.correo = item.correo;
      this.condicion = item.condicion;
      this.dialog = true;
    },
    actualizarRegistro() {
      this.overlay = true;
      axios
        .put("api/Medicos/Actualizar", {
          id_medico: this.id_medico,
          id_consultorio: this.id_consultorio,
          nombre: this.nombre,
          correo: this.correo,
          condicion: this.condicion,
        })
        .then(() => {
          this.overlay = false;
          this.dialog = false;
          this.$refs.form.reset();
          this.limpiarVariables();
          this.superUsuario();
          this.alerta("Registro actualizado con exito", "success");
        })
        .catch((error) => {
          this.overlay = false;
          console.log(error);
          this.alerta("Sucedio algún error", "error");
        });
    },
    limpiarVariables() {
      this.id_medico = 0;
      this.id_consultorio = 0;
      this.nombre = "";
      this.correo = "";
      this.condicion = false;
      this.medicos = [];
    },
    colorEstado(condicion) {
      if (condicion) {
        return "success";
      } else {
        return "red";
      }
    },
    alerta(texto, tipo_alerta) {
      this.$swal({
        toast: true,
        position: "top-end",
        title: texto,
        icon: tipo_alerta,
        showConfirmButton: false,
        timer: 5000,
        backdrop: false,
      });
    },
  },
};
</script>