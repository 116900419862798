<template>
  <v-container fluid style="height: 100%">
    <v-data-table
      :headers="headers"
      :items="citas"
      :items-per-page="-1"
      class="elevation-1"
      group-by="nombre_medico"
      show-group-by
      sort-by="nombre_medico"
    >
      <!-- TOP DATA TABLE -->
      <template v-slot:top>
        <v-toolbar flat>
          <v-menu :close-on-content-click="false" offset-y max-width="900">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" right outlined>
                Filtrar
                <v-icon small color="primary">fa-solid fa-filter</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="5">
                    <v-text-field
                      v-model="fecha_desde"
                      label="Fecha de desde"
                      type="date"
                      hide-details
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="5">
                    <v-text-field
                      v-model="fecha_hasta"
                      label="Fecha de hasta"
                      type="date"
                      hide-details
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2" align-self="center">
                    <v-btn color="primary" @click="listarCitasEntreFechas()">
                      Filtrar
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="6" class="pt-0" align-self="center">
                    <h2>Total de citas: {{ total_citas }}</h2>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-card-actions class="pl-0">
                  <v-btn
                    @click="DescargarExcel()"
                    color="info"
                    :disabled="citas.length == 0"
                    >Descargar</v-btn
                  >
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-toolbar>
      </template>

      <template v-slot:[`item.fecha_creacion`]="{ item }">
        <div>
          <span>{{ formatoFecha(item.fecha_creacion) }}</span>
        </div>
      </template>

      <template v-slot:[`item.fecha_nacimiento_paciente`]="{ item }">
        <div>
          <span>{{ calcularEdad(item.fecha_nacimiento_paciente) }}</span>
        </div>
      </template>

      <template v-slot:[`item.nombre_paciente`]="{ item }">
        <div>
          <span>{{ item.nombre_paciente + " " + item.apellido_paciente }}</span>
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
var XLSX = require("xlsx");

export default {
  data: () => ({
    headers: [
      { text: "Fecha creación", value: "fecha_creacion", groupable: false },
      { text: "Médico", value: "nombre_medico" },
      {
        text: "Número de caso",
        value: "n_caso_paciente",
        groupable: false,
        width: "50",
      },
      { text: "Edad", value: "fecha_nacimiento_paciente", groupable: false },
      { text: "Paciente", value: "nombre_paciente", groupable: false },
      { text: "Pasaporte", value: "pasaporte_paciente", groupable: false },
    ],
    citas: [],
    fecha_desde:
      new Date().getFullYear() +
      "-" +
      String(new Date().getMonth() + 1).padStart(2, "0") +
      "-" +
      String(new Date().getDate()).padStart(2, "0"),
    fecha_hasta:
      new Date().getFullYear() +
      "-" +
      String(new Date().getMonth() + 1).padStart(2, "0") +
      "-" +
      String(new Date().getDate()).padStart(2, "0"),
    total_citas: 0,
    items_tipos: [],
  }),
  created() {
    this.listarCitasEntreFechas();
  },
  methods: {
    listarCitasEntreFechas() {
      axios
        .get(
          "api/Citas/ListarTodos/" + this.fecha_desde + "/" + this.fecha_hasta
        )
        .then((response) => {
          this.citas = response.data;
          this.total_citas = this.citas.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    calcularEdad(item) {
      var hoy = new Date();
      var cumpleanios = new Date(item);
      var edad = hoy.getFullYear() - cumpleanios.getFullYear();
      var m = hoy.getMonth() - cumpleanios.getMonth();
      if (m < 0 || (m === 0 && hoy.getDate() < cumpleanios.getDate())) {
        edad--;
      }
      return edad;
    },
    formatoFecha(fecha) {
      return moment(new Date(fecha)).format("YYYY-MM-DD");
    },
    DescargarExcel() {
      const excel_data = [];
      this.citas.forEach((element) => {
        excel_data.push({
          "FECHA CREACIÓN": element.fecha_creacion,
          "NÚMERO DE CASO": element.n_caso_paciente,
          MÉDICO: element.nombre_medico,
          EDAD: this.calcularEdad(element.fecha_nacimiento_paciente),
          NOMBRE: element.nombre_paciente + " " + element.apellido_paciente,
          PASAPORTE: element.pasaporte_paciente,
          REPROGRAMADA: element.fue_movido ? "SI" : "NO",
          "GRUPAL O INDIVIDUAL": element.es_grupal_individual
            ? "GRUPAL"
            : "INDIVIDUAL",
          "CITA VENCIDA": element.cita_vencida ? "SI" : "NO",
        });
      });

      let data = XLSX.utils.json_to_sheet(excel_data);
      const workbook = XLSX.utils.book_new();
      const filename = "Citas " + this.fecha_desde + " " + this.fecha_hasta;
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
  },
};
</script>