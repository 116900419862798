<template>
  <v-row no-gutters class="fill-height">
    <v-col>
      <v-data-table :headers="headers" :items="modulos" class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Módulos</v-toolbar-title>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-col>
    <v-overlay v-model="overlay">
      <lottie-player
        autoplay
        loop
        mode="normal"
        src="https://assets6.lottiefiles.com/packages/lf20_uwR49r.json"
        style="width: 200px"
      >
      </lottie-player>
    </v-overlay>
  </v-row>
</template>

<script>
import axios from "axios";
import "@lottiefiles/lottie-player";

export default {
  data: () => ({
    headers: [
      { text: "#", align: "center", value: "id_modulo" },
      { text: "Nombre", align: "center", value: "nombre" },
    ],
    modulos: [],
    overlay: false
  }),
  created() {
    this.listarModulos();
  },
  methods: {
    listarModulos() {
      this.overlay = true;
      axios
        .get("api/Modulos/Listar/" + this.$store.state.usuario.idUsuario)
        .then((response) => {
          this.overlay = false;
          this.modulos = response.data;
        })
        .catch((error) => {
          this.overlay = false;
          console.log(error);
        });
    },
  },
};
</script>