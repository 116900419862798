import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from "axios";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueCookies from 'vue-cookies'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
//import telegramApi from 'telegram-api-vue';

Vue.use(VueSweetalert2);
Vue.use(VueCookies);
Vue.use(VueTelInputVuetify, {
  vuetify,
});

axios.defaults.baseURL = process.env.VUE_APP_URL_API;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
