<template>
  <v-container fluid>
    <v-row no-gutters class="fill-height">
      <v-col cols="auto">
        <v-chip color="primary" class="ma-1">
          <v-icon small left>fa-solid fa-phone</v-icon>
          Llamada pendiente</v-chip
        >
      </v-col>
      <v-col cols="auto">
        <v-chip color="green" dark class="ma-1">
          <v-icon small left>fa-solid fa-phone</v-icon>
          Llamada realizada</v-chip
        >
      </v-col>
      <v-col cols="auto">
        <v-chip color="black" class="ma-1" outlined>
          <v-icon small left>fa-solid fa-users-viewfinder</v-icon>
          Grupo familiar
        </v-chip>
      </v-col>
      <v-col cols="auto">
        <v-chip color="black" class="ma-1" outlined>
          <v-icon small left>fa-solid fa-user</v-icon>
          Individual
        </v-chip>
      </v-col>
      <v-col cols="auto">
        <v-chip color="black" class="ma-1" outlined>
          <v-icon small left>fa-solid fa-clock</v-icon>
          Pendiente
        </v-chip>
      </v-col>
      <v-spacer></v-spacer>
      <v-col
        cols="auto"
        v-if="this.$store.state.usuario.nombre == 'User Devco'"
      >
        <v-select
          v-model="cita_x_id_medico"
          :items="items_medicos"
          item-value="id_medico"
          item-text="nombre"
          label="Médico"
          outlined
          dense
          hide-details
          @change="listarCitasXMedico()"
        ></v-select>
      </v-col>
      <v-col cols="12">
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn outlined color="grey darken-2" @click="setToday">
              Hoy
            </v-btn>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  outlined
                  small
                  @click="prev()"
                  color="grey darken-2"
                  class="mx-1"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon large> mdi-chevron-left </v-icon>
                </v-btn>
              </template>
              <span>Atras</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  outlined
                  small
                  @click="next()"
                  color="grey darken-2"
                  class="mx-1"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon large> mdi-chevron-right </v-icon>
                </v-btn>
              </template>
              <span>Adelante</span>
            </v-tooltip>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <div
              class="mr-3 headline font-weight-bold"
              v-if="$vuetify.breakpoint.mdAndUp ? true : false"
            >
              Citas registradas
            </div>
            <v-spacer></v-spacer>
            <v-menu bottom right>
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      outlined
                      color="grey darken-2"
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...menu }"
                      ><span>{{ typeToLabel[type] }}</span
                      ><v-icon large right> mdi-menu-down </v-icon></v-btn
                    >
                  </template>
                  <span>Formato calendario</span>
                </v-tooltip>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Día</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Semana</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Mes</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>4 Días</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet>
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events"
            :type="type"
            @click:event="showEvent"
            @change="superUsuario"
            @click:more="viewDay"
            @click:date="viewDay"
          >
            <template v-slot:event="{ event }">
              <v-icon color="white" small class="px-1">{{ event.icon }}</v-icon>
              <v-icon
                color="white"
                small
                class="px-1"
                v-if="event.fue_movido != null"
                >{{ event.fue_movido }}</v-icon
              >
              <v-icon
                color="white"
                small
                class="px-1"
                v-if="event.pendiente != null"
                >{{ event.pendiente }}</v-icon
              >
              {{ event.name }}
            </template>
          </v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
            :close-on-click="false"
          >
            <v-card color="grey lighten-4" max-width="600px" flat>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <span class="title">Médico</span> <br />
                    <v-text-field
                      v-model="selectedEventMedico.nombre"
                      label="Nombre"
                      outlined
                      readonly
                      hide-details
                      dense
                      class="mt-2"
                    ></v-text-field>
                    <v-text-field
                      v-model="selectedEventMedico.correo"
                      label="Correo"
                      outlined
                      readonly
                      hide-details
                      dense
                      class="mt-2"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <span class="title">Paciente</span><br />                    
                    <v-text-field
                      v-model="selectedEvent.uid"
                      label="UID"
                      outlined
                      readonly
                      hide-details
                      dense
                      class="mt-2"
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="selectedEvent.nombre"
                      label="Nombre"
                      outlined
                      readonly
                      hide-details
                      dense
                      class="mt-2"
                    ></v-text-field>
                    <v-text-field
                      v-model="selectedEvent.apellido"
                      label="Apellido"
                      outlined
                      readonly
                      hide-details
                      dense
                      class="mt-2"
                    ></v-text-field>
                    <v-text-field
                      v-model="selectedEvent.pasaporte"
                      label="Pasaporte"
                      outlined
                      readonly
                      hide-details
                      dense
                      class="mt-2"
                    ></v-text-field>
                    <v-text-field
                      v-model="selectedEvent.n_caso"
                      label="Numero de caso"
                      outlined
                      readonly
                      hide-details
                      dense
                      class="mt-2"
                    ></v-text-field>
                    <v-row no-gutters>
                      <v-col cols="4">
                        <v-text-field
                          v-model="selectedEvent.telefono_n1"
                          label="Teléfono 1"
                          outlined
                          readonly
                          hide-details
                          dense
                          class="mt-2"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-model="selectedEvent.telefono_n2"
                          label="Teléfono 2"
                          outlined
                          readonly
                          hide-details
                          dense
                          class="mt-2"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-model="selectedEvent.telefono_n3"
                          label="Teléfono 3"
                          outlined
                          readonly
                          hide-details
                          dense
                          class="mt-2"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-text-field
                      v-model="formatoVModel"
                      label="Fecha de nacimiento"
                      outlined
                      readonly
                      hide-details
                      dense
                      class="mt-2"
                    ></v-text-field>
                    <v-textarea
                      v-model="selectedEvent.direccion"
                      label="Dirección"
                      outlined
                      readonly
                      hide-details
                      dense
                      row-height="1"
                      auto-grow
                      class="mt-2"
                    ></v-textarea>
                    <v-text-field
                      v-model="selectedEvent.correo"
                      label="Correo"
                      outlined
                      readonly
                      hide-details
                      dense
                      class="mt-2"
                    ></v-text-field>
                    <v-textarea
                      v-model="nombre_tipo_visa"
                      label="Tipo de visa"
                      :hint="descripcion_tipo_visa"
                      persistent-hint
                      outlined
                      auto-grow
                      dense
                      class="mt-2"
                      row-height="13"
                    >
                    </v-textarea>
                    <v-text-field
                      v-model="fecha_consular"
                      label="Fecha consular"
                      outlined
                      readonly
                      hide-details
                      dense
                      class="mt-2"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn text outlined @click="selectedOpen = false">
                  Cerrar
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text outlined @click="dialog_editar = true"
                  >Editar cita</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-col>

      <v-dialog v-model="dialog_eliminar" persistent width="500">
        <v-card height="auto">
          <v-card-title>¿Está seguro de eliminar la cita?</v-card-title>
          <v-card-text>
            <p class="font-weight-bold">
              Esta acción es irreversible y no se podrá devolver la información
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn color="red" dark @click="eliminarCita()">Si, eliminar</v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="dialog_eliminar = false" outlined>Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog_editar" width="800">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6" align-self="center" class="mt-3">
                <span class="title black--text">Llamada al aplicante</span>
              </v-col>
              <v-col cols="12" md="6" align-self="center" class="mt-3">
                <v-btn color="primary" block @click="actualizarLlamadaCita()"
                  >Se Llamo al aplicante</v-btn
                >
              </v-col>
              <v-col cols="12" md="6">
                <v-checkbox
                  v-model="bool_pendiente"
                  label="CITA PENDIENTE"
                  class="pt-0"
                  color="primary"
                  block
                  hide-details
                  @click="validarPendiente()"
                  >Pendiente</v-checkbox
                >
              </v-col>
              <v-col cols="12" v-if="bool_pendiente">
                <v-textarea
                  v-model="mensaje_pendiente"
                  label="Motivo de Pendiente"
                  outlined
                  hide-details
                  auto-grow
                  row-height="10"
                  dense
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6" align-self="center">
                <v-btn color="primary" block @click="actualizarPendiente()"
                  >Guardar cita pendiente</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="dialog_editar = false" outlined text>Cancelar</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              color="red"
              @click="dialogCita()"
              v-if="this.$store.state.usuario.rol == 'Administrador'"
              >Eliminar cita</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-overlay v-model="overlay">
        <lottie-player
          autoplay
          loop
          mode="normal"
          src="https://assets6.lottiefiles.com/packages/lf20_uwR49r.json"
          style="width: 200px"
        >
        </lottie-player>
      </v-overlay>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import "@lottiefiles/lottie-player";

export default {
  data: () => ({
    id_cita: 0,
    id_paciente: 0,
    id_medico: 0,
    id_cupo: 0,
    fecha_creacion: "",
    fecha_cita: "",
    fecha_consular: "",
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Mes",
      week: "Semana",
      day: "Día",
      "4day": "4 Días",
    },
    selectedEvent: {},
    selectedEventMedico: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    overlay: false,
    dialog_eliminar: false,
    dialog_editar: false,
    motivo_eliminar: "",
    id_cupo_actualizar: 0,
    items_medicos: [],
    cita_x_id_medico: 0,
    bool_pendiente: false,
    mensaje_pendiente: "",
    id_tipo_visa: "",
    items_tipo_visas: [],
    nombre_tipo_visa: "",
    descripcion_tipo_visa: ""
  }),
  mounted() {
    this.$refs.calendar.checkChange();
  },
  computed: {
    formatoVModel() {
      return this.formatoFechaV2(this.selectedEvent.fecha_nacimiento);
    },
  },
  created() {
    this.listarMedicos();
    this.listarTipoVisas();
  },
  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      //console.log(event);
      const open = () => {
        this.datosPaciente(event);
        this.datosMedico(event);

        this.id_cita = event.id_cita;
        this.id_paciente = event.id_paciente;
        this.id_medico = event.id_medico;
        this.id_cupo = event.id_cupo;
        this.fecha_creacion = event.fecha_creacion;
        this.fecha_cita = event.start;
        this.selectedElement = nativeEvent.target;
        this.bool_pendiente = event.pendiente;
        this.mensaje_pendiente = event.mensaje_pendiente;
        this.id_tipo_visa = event.id_tipo_visa;
        this.fecha_consular = event.fecha_consular;
        this.nombre_tipo_visa = event.nombre_tipo_visa;
        this.descripcion_tipo_visa = event.descripcion_tipo_visa;
        
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    superUsuario() {
      if (this.$store.state.usuario.super_usuario == "True") {
        this.listarCitasSuperUsuario();
      } else {
        this.listarCitas();
      }
    },
    listarCitasSuperUsuario() {
      const events = [];
      this.overlay = true;
      axios
        .get("api/Citas/Listar/" + localStorage.getItem("docto_visa_dr"))
        .then((response) => {
          //console.log(response.data);
          this.overlay = false;
          response.data.forEach((element) => {
            events.push({
              id_cita: element.id_cita,
              id_medico: element.id_medico,
              id_paciente: element.id_paciente,
              estado: element.estado,
              fecha_creacion: element.fecha_creacion,
              name: element.nombre_paciente + " " + element.apellido_paciente,
              start: this.formatoFecha(element.fecha_cita),
              end: "",
              color: this.colorCita(element.fue_llamado),
              timed: true,
              id_cupo: element.id_cupo,
              icon: this.iconGrupoIndividual(element.es_grupal_individual),
              fue_movido: this.iconFueMovido(element.fue_movido),
              pendiente: this.iconPendiente(element.pendiente),
              mensaje_pendiente: element.mensaje_pendiente,
              fecha_consular: this.formatoFecha(element.fecha_consular),
              id_tipo_visa: element.id_tipo_visa,
              nombre_tipo_visa: element.nombre_tipo_visa,
              descripcion_tipo_visa: element.descripcion_tipo_visa
            });
          });
          this.events = events;
        })
        .catch((error) => {
          this.overlay = false;
        });
    },
    listarCitas() {
      const events = [];
      this.overlay = true;
      axios
        .get("api/Citas/Listar/" + localStorage.getItem("docto_visa_dr"))
        .then((response) => {
          //console.log(response.data);
          this.overlay = false;
          response.data.forEach((element, index) => {
            events.push({
              id_cita: element.id_cita,
              id_medico: element.id_medico,
              id_paciente: element.id_paciente,
              estado: element.estado,
              fecha_creacion: element.fecha_creacion,
              name: element.nombre_paciente + " " + element.apellido_paciente,
              start: this.formatoFecha(element.fecha_cita),
              end: "",
              color: this.colorCita(element.fue_llamado),
              timed: true,
              id_cupo: element.id_cupo,
              icon: this.iconGrupoIndividual(element.es_grupal_individual),
              fue_movido: this.iconFueMovido(element.fue_movido),
              pendiente: this.iconPendiente(element.pendiente),
              mensaje_pendiente: element.mensaje_pendiente,
              fecha_consular: this.formatoFecha(element.fecha_consular),
              id_tipo_visa: element.id_tipo_visa,
              nombre_tipo_visa: element.nombre_tipo_visa,
              descripcion_tipo_visa: element.descripcion_tipo_visa
            });
          });
          this.events = events;
        })
        .catch((error) => {
          this.overlay = false;
        });
    },
    listarCitasXMedico() {
      const events = [];
      this.overlay = true;

      axios
        .get("api/Citas/ListarPorMedico/" + this.cita_x_id_medico)
        .then((response) => {
          //console.log(response.data);
          this.overlay = false;
          response.data.forEach((element) => {
            events.push({
              id_cita: element.id_cita,
              id_medico: element.id_medico,
              id_paciente: element.id_paciente,
              estado: element.estado,
              fecha_creacion: element.fecha_creacion,
              name: element.nombre_paciente + " " + element.apellido_paciente,
              start: this.formatoFecha(element.fecha_cita),
              end: "",
              color: this.colorCita(element.fue_llamado),
              timed: true,
              estado: element.estado,
              id_cupo: element.id_cupo,
              icon: this.iconGrupoIndividual(element.es_grupal_individual),
              fue_movido: this.iconFueMovido(element.fue_movido),
              pendiente: this.iconPendiente(element.pendiente),
              mensaje_pendiente: element.mensaje_pendiente,
              fecha_consular: this.formatoFecha(element.fecha_consular),
              id_tipo_visa: element.id_tipo_visa,
              nombre_tipo_visa: element.nombre_tipo_visa,
              descripcion_tipo_visa: element.descripcion_tipo_visa
            });
          });
          this.events = events;
        })
        .catch((error) => {
          this.overlay = false;
        });
    },
    formatoFecha(fecha) {
      if (!fecha) {
        return;
      }
      return moment(fecha).format("YYYY-MM-DD");
    },
    formatoFechaV2(fecha) {
      if (!fecha) {
        return;
      }
      return moment(fecha).format("DD-MM-YYYY");
    },
    datosPaciente(event) {
      axios
        .get(
          "api/Pacientes/Listar/" +
            event.id_paciente +
            "/" +
            this.$store.state.usuario.idUsuario
        )
        .then((response) => {
          this.selectedEvent = response.data;
        })
        .catch((error) => {});
    },
    datosMedico(event) {
      axios
        .get(
          "api/Medicos/Listar/" +
            event.id_medico +
            "/" +
            this.$store.state.usuario.idUsuario
        )
        .then((response) => {
          this.selectedEventMedico = response.data;
        })
        .catch((error) => {});
    },
    dialogCita() {
      this.dialog_eliminar = true;
    },
    eliminarCita() {
      this.overlay = true;
      axios
        .delete("api/Citas/Eliminar/" + this.id_cita)
        .then(() => {
          this.bitacora(
            "Cita Eliminada " +
              "Aplicante: " +
              this.selectedEvent.nombre +
              " " +
              this.selectedEvent.apellido +
              " UID: " +
              this.selectedEvent.uid,
            this.fecha_cita,
            this.id_medico,
            0
          );
          this.overlay = false;
          this.dialog_eliminar = false;
          this.alerta("Registro eliminado con éxito", "success");
          this.motivo_eliminar = "";

          this.superUsuario();

          this.dialog_editar = false;
          this.selectedOpen = false;
        })
        .catch((error) => {
          this.overlay = false;
        });
    },
    agregarCupo() {
      axios.put("api/Cupos/AgregarCupo/" + this.id_cupo).then(() => {
        this.superUsuario();
      });
    },
    indiceCitaRegistrada() {
      //AL ELIMINAR UNA CITA SE REMUEVE DE CUPOS LAS CITA ACUMULADA
      return axios.put(
        "api/Cupos/QuitarCitasRegistradas/" + this.id_cupo + "/" + 1
      );
    },
    bitacora(descripcion, fecha_evento, id_medico, paciente) {
      var today = new Date();
      var fecha =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();

      axios
        .post("api/Bitacoras/Crear", {
          id_usuario: this.$store.state.usuario.idUsuario,
          fecha: fecha,
          descripcion: descripcion,
          fecha_evento: fecha_evento,
          medico_asignado: id_medico,
          paciente: paciente,
          n_cupos: -1,
        })
        .catch((error) => {});
    },
    alerta(texto, tipo_alerta) {
      this.$swal({
        toast: true,
        position: "top-end",
        title: texto,
        icon: tipo_alerta,
        showConfirmButton: false,
        timer: 5000,
        backdrop: false,
      });
    },
    actualizarLlamadaCita() {
      axios
        .put("api/Citas/ActualizarLlamadaCita", {
          id_cita: this.id_cita,
          fue_llamado: true,
        })
        .then(() => {
          this.dialog_editar = false;
          this.selectedOpen = false;
          this.alerta("Cita actualizada con exito.", "success");
          this.superUsuario();
        });
    },
    actualizarPendiente() {
      axios
        .put("api/Citas/ActualizarPendiente", {
          id_cita: this.id_cita,
          pendiente: this.bool_pendiente,
          mensaje_pendiente: this.mensaje_pendiente,
        })
        .then(() => {
          this.dialog_editar = false;
          this.selectedOpen = false;
          this.alerta("Cita actualizada con exito.", "success");
          this.superUsuario();
        })
        .catch(() => {
          this.alerta("No se pudo actualizar la cita.", "error");
        });
    },
    validarPendiente() {
      if (!this.bool_pendiente) {
        this.mensaje_pendiente = "";
      }
    },
    alerta(texto, tipo_alerta) {
      this.$swal({
        toast: true,
        position: "top-end",
        title: texto,
        icon: tipo_alerta,
        showConfirmButton: false,
        timer: 5000,
        backdrop: false,
      });
    },
    colorCita(estatus) {
      if (estatus) {
        return "green";
      } else {
        return "blue";
      }
    },
    iconGrupoIndividual(item) {
      if (item) {
        return "fa-solid fa-users-viewfinder";
      } else {
        return "fa-solid fa-user";
      }
    },
    listarMedicos() {
      axios.get("api/Medicos/Listar").then((response) => {
        this.items_medicos = response.data;
      });
    },
    listarTipoVisas() {
      axios.get("api/TipoVisas/Listar").then((response) => {
        //console.log(response.data);
        this.items_tipo_visas = response.data;
      });
    },
    iconFueMovido(item) {
      if (item) {
        return "fas fa-exchange-alt";
      } else {
        return null;
      }
    },
    iconPendiente(item) {
      if (item) {
        return "fa-solid fa-clock";
      } else {
        return null;
      }
    },
  },
};
</script>