import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import es from 'vuetify/lib/locale/es'
import '@fortawesome/fontawesome-free/css/all.css'

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { es },
    current: 'es'
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#1976d2',
        primary100: '#90caf9',
        error: '#FF0000',
        success: '#25BA2B',
        warning: '#FF9800',
        cancel: '#BDBDBD',
        aqua: '#00d4ff',
      },
    },
  },
  icons: {
    iconfont: 'md',
    iconfont: 'fa',
  },
});
