<template>
  <v-row no-gutters class="fill-height">
    <v-col cols="12">
      <v-data-table :headers="headers" :items="items" class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Busqueda</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <div class="font-weight-bold" v-if="$vuetify.breakpoint.mdAndUp">
              [Buscar por nombre, pasaporte o numero de caso]
            </div>
            <v-spacer></v-spacer>
            <v-text-field
              v-if="$vuetify.breakpoint.mdAndUp"
              v-model="busqueda"
              label="Busqueda"
              outlined
              dense
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="listarpacientes()"
              v-if="$vuetify.breakpoint.mdAndUp"
              >BUSCAR</v-btn
            >
            <v-menu
              v-if="$vuetify.breakpoint.mdAndDown"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <i class="fa-solid fa-ellipsis-vertical fa-2x"></i>
                </v-btn>
              </template>
              <v-card width="250">
                <v-card-text>
                  <div class="font-weight-bold">
                    [Buscar por nombre, pasaporte o numero de caso]
                  </div>
                  <v-text-field
                    v-model="busqueda"
                    label="Busqueda"
                    outlined
                    dense
                    hide-details
                    class="mt-1"
                  ></v-text-field>
                  <v-btn color="primary" @click="listarpacientes()" class="mt-1"
                    >BUSCAR</v-btn
                  >
                </v-card-text>
              </v-card>
            </v-menu>
          </v-toolbar>
        </template>
        <template v-slot:[`item.fecha_creacion`]="{ item }">
          <span>{{ formatoFecha(item.fecha_creacion) }}</span>
        </template>
        <template v-slot:[`item.fecha_cita`]="{ item }">
          <span>{{ formatoFecha(item.fecha_cita) }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                @click="verItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-col>

    <v-dialog v-model="dialog" max-width="800" persistent>
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="fecha_creacion"
                  label="Fecha creación"
                  outlined
                  readonly
                  dense
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="fecha_cita"
                  label="Fecha cita"
                  outlined
                  readonly
                  dense
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="uid"
                  label="UID"
                  outlined
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="nombre"
                  label="Nombre"
                  outlined
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="apellido"
                  label="Apellido"
                  outlined
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="pasaporte"
                  label="Pasaporte"
                  outlined
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="n_caso"
                  label="Numero de caso"
                  outlined
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="telefono_n1"
                  label="Teléfono 1"
                  outlined
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="telefono_n2"
                  label="Teléfono 2"
                  outlined
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="telefono_n3"
                  label="Teléfono 3"
                  outlined
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="fecha_nacimiento"
                  label="Fecha de nacimiento"
                  outlined
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="direccion"
                  label="Dirección"
                  outlined
                  auto-grow
                  row-height="1"
                  readonly
                  dense
                  hide-details
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="correo"
                  label="Correo"
                  outlined
                  auto-grow
                  row-height="1"
                  readonly
                  dense
                  hide-details
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" @click="verCita()">Ver cita</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue" text @click="cerrarDialog()"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogCita" max-width="1000" persistent>
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-data-table :headers="headers_citas" :items="items_citas">
            <template v-slot:[`item.fecha_creacion`]="{ item }">
              <span>{{ formatoFecha(item.fecha_creacion) }}</span>
            </template>
            <template v-slot:[`item.fecha_cita`]="{ item }">
              <span>{{ formatoFecha(item.fecha_cita) }}</span>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" text @click="cerrarDialogCitas()"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  data: () => ({
    headers: [
      { text: "Fecha creación", value: "fecha_creacion" },
      { text: "Fecha cita", value: "fecha_cita" },
      { text: "UID", value: "uid" },
      { text: "Nombre", value: "nombre" },
      { text: "Pasaporte", value: "pasaporte" },
      { text: "Numero de caso", value: "n_caso" },
      { text: "Opciones", value: "actions", sortable: false },
    ],
    items: [],
    dialog: false,
    //PACIENTE
    id_paciente: null,
    id_cita: null,
    id_medico: null,
    uid: null,
    nombre: null,
    apellido: null,
    pasaporte: null,
    n_caso: null,
    telefono_n1: null,
    telefono_n2: null,
    telefono_n3: null,
    fecha_nacimiento: null,
    direccion: null,
    correo: null,
    fecha_creacion: "",
    fecha_cita: "",
    //CITA, MEDICO
    headers_citas: [
      { text: "Fecha de creación de la cita", value: "fecha_creacion" },
      { text: "Fecha de la cita", value: "fecha_cita" },
      { text: "Nombre del médico", value: "nombre_medico" },
      { text: "Correo del medico", value: "correo_medico" },
      { text: "Motivo de eliminación", value: "motivo_liminar" },
    ],
    items_citas: [],
    dialogCita: false,
    fecha_creacion_cita: null,
    fecha_cita: null,
    nombre_medico: null,
    correo_medico: null,
    motivo_eliminar: null,
    //////////////////////////
    busqueda: null,
    valorBusqueda: null,
    itemsBusqueda: ["NOMBRE", "PASAPORTE", "NUMERO DE CASO"],
  }),
  methods: {
    listarpacientes() {
      if (!this.busqueda) {
        this.alerta(
          "Deberá de Colocar un Nombre, Pasaporte o Número de caso para la búsqueda.",
          "info"
        );
        return;
      }

      axios
        .post("api/Pacientes/ListarPorBusqueda", {
          id_usuario: this.$store.state.usuario.idUsuario,
          nombre: this.busqueda,
          pasaporte: this.busqueda,
          n_caso: this.busqueda,
        })
        .then((response) => {
          response.data.forEach((element) => {
            if (
              this.$store.state.usuario.id_consultorio == element.id_consultorio
            ) {
              this.items.push(element);
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    verItem(item) {
      this.id_paciente = item.id_paciente;
      this.uid = item.uid;
      this.nombre = item.nombre;
      this.apellido = item.apellido;
      this.pasaporte = item.pasaporte;
      this.n_caso = item.n_caso;
      this.telefono_n1 = item.telefono_n1;
      this.telefono_n2 = item.telefono_n2;
      this.telefono_n3 = item.telefono_n3;
      this.fecha_nacimiento = this.formatoFecha(item.fecha_nacimiento);
      this.direccion = item.direccion;
      this.correo = item.correo;
      this.fecha_creacion = this.formatoFecha(item.fecha_creacion);
      this.fecha_cita = this.formatoFecha(item.fecha_cita);
      this.dialog = true;
    },
    verCita() {
      axios
        .get(
          "api/Citas/ListarPorPaciente/" +
            this.id_paciente +
            "/" +
            this.$store.state.usuario.idUsuario
        )
        .then((response) => {
          this.items_citas = response.data;
          this.dialogCita = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatoFecha(fecha) {
      return moment(fecha).format("DD-MM-YYYY");
    },
    cerrarDialog() {
      this.uid = null;
      this.nombre = null;
      this.apellido = null;
      this.pasaporte = null;
      this.n_caso = null;
      this.telefono_n1 = null;
      this.telefono_n2 = null;
      this.telefono_n3 = null;
      this.fecha_nacimiento = null;
      this.direccion = null;
      this.correo = null;

      this.dialog = false;
    },
    cerrarDialogCitas() {
      this.dialogCita = false;
    },
    alerta(texto, tipo_alerta) {
      this.$swal({
        toast: true,
        position: "top-end",
        title: texto,
        icon: tipo_alerta,
        showConfirmButton: false,
        timer: 5000,
        backdrop: false,
      });
    },
  },
};
</script>