<template>
  <v-container fluid style="height: 100%">
    <v-row no-gutters justify="center" align="center" style="height: 100%">
      <v-card elevation="0" width="1000" color="transparent">
        <v-row no-gutters>
          <v-col cols="12" md="6">
            <v-img
              :src="require('@/assets/logo_1_removed.png')"
              class="mx-auto"
              max-width="600"
              max-height="600"
              contain
            ></v-img>
          </v-col>
          <v-col cols="12" md="6" align-self="center">
            <v-card elevation="0" color="transparent">
              <v-card-title class="px-1"
                ><p
                  class="mx-auto font-weight-black"
                  :class="
                    $vuetify.breakpoint.mdAndUp ? 'display-3' : 'display-2'
                  "
                >
                  Bienvenido
                </p></v-card-title
              >
              <v-card-text class="text-center subtitle-1"
                >Haz tu cita de forma rápida y sencilla</v-card-text
              >
              <v-card-actions>
                <v-row justify="center">
                  <v-card elevation="0" color="transparent">
                    <v-col>
                      <v-btn
                        class="mx-auto"
                        color="aqua"
                        rounded
                        @click="irReservarCita()"
                        >Registrar cita individual</v-btn
                      >
                    </v-col>
                    <v-col>
                      <v-btn
                        class="mx-auto"
                        color="aqua"
                        rounded
                        @click="irReservarCitaFamiliar()"
                        >Registrar grupo familiar</v-btn
                      >
                    </v-col>
                  </v-card>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-row>

    <v-dialog v-model="dialog" width="500" v-if="logueado">
      <v-card>
        <v-card-title>IMPORTANTE!</v-card-title>
        <v-card-text class="text-justify black--text">
          ESTIMADO SOLICITANTE, SI DESEA PROGRAMAR CITA PARA SU GRUPO FAMILIAR,
          AUNQUE TENGAN DIFERENTES NÚMEROS DE CASO, FAVOR DAR CLICK EN LA OPCION
          "REGISTRAR GRUPO FAMILIAR"
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialog = !dialog">Continuar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import router from "@/router";

export default {
  data: () => ({
    codigo: null,
    dialog: true,
  }),
  created() {
    //
  },
  computed: {
    logueado() {
      if (!this.$store.state.usuario) {
        return true;
      }
    },
  },
  methods: {
    irReservarCita() {
      router.push({ name: "reservar_cita" });
    },
    irReservarCitaFamiliar() {
      router.push({ name: "reservar_cita_grupo" });
    },
  },
};
</script>

<style>
.textoPequeño {
  font-size: 50px;
}
.textoGrande {
  font-size: 80px;
}
</style>