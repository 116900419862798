<template>
  <v-row no-gutters class="fill-height">
    <v-col>
      <v-data-table
        :headers="headers"
        :items="consultorios"
        class="elevation-1"
        :search="busqueda"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Consultorios</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="busqueda"
              label="Busqueda"
              outlined
              dense
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="nuevo()"
              v-if="!esAdministrador"
            >
              Nuevo
            </v-btn>
            <v-dialog v-model="dialog" max-width="800px" persistent>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ tituloDialog }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-form ref="form" v-model="validForm">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="nombre"
                            label="Nombre"
                            outlined
                            :rules="requerido"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="direccion"
                            label="Dirección"
                            outlined
                            :rules="requerido"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="cerrarDialog()">
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    :disabled="esAdministrador"
                    text
                    @click="guardarRegistro()"
                    v-if="flag === -1"
                  >
                    Guardar
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    :disabled="esAdministrador"
                    text
                    @click="actualizarRegistro()"
                    v-if="flag === 1"
                  >
                    Actualizar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                @click="editarItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-col>

    <v-overlay v-model="overlay">
      <lottie-player
        autoplay
        loop
        mode="normal"
        src="https://assets6.lottiefiles.com/packages/lf20_uwR49r.json"
        style="width: 200px"
      >
      </lottie-player>
    </v-overlay>
  </v-row>
</template>
<script>
import axios from "axios";
import "@lottiefiles/lottie-player";

export default {
  data: () => ({
    busqueda: "",
    dialog: false,
    dialogEliminar: false,
    headers: [
      { text: "Nombre", value: "nombre" },
      { text: "Dirección", value: "direccion" },
      { text: "Telegram Chat ID", value: "telegram_chat_id" },
      { text: "Opciones", value: "actions", align: "center", sortable: false },
    ],
    consultorios: [],
    id_consultorio: 0,
    nombre: "",
    direccion: "",
    //variables varios
    validForm: false,
    flag: -1,
    requerido: [(v) => !!v || "El campo es obligatorio"],
    overlay: false,
  }),
  computed: {
    tituloDialog() {
      return this.flag === -1 ? "Nuevo Registro" : "Editar Registro";
    },
    esAdministrador() {
      if (this.$store.state.usuario) {
        if (this.$store.state.usuario.rol == "Administrador") {
          return false;
        } else {
          return true;
        }
      }
    },
  },

  created() {
    this.superUsuario();
  },
  methods: {
    superUsuario() {
      if (this.$store.state.usuario.super_usuario == "True") {
        this.listarConsultorioSuperUsuario();
      } else {
        this.listarConsultorios();
      }
    },
    listarConsultorioSuperUsuario() {
      this.overlay = true;
      axios
        .get("api/Consultorios/Listar/" + this.$store.state.usuario.idUsuario)
        .then((response) => {
          this.overlay = false;
          this.consultorios = response.data;
        })
        .catch((error) => {
          this.overlay = false;
          console.log(error);
          this.alerta("Sucedio algún error", "error");
        });
    },
    listarConsultorios() {
      this.overlay = true;
      axios
        .get("api/Consultorios/Listar/" + this.$store.state.usuario.idUsuario)
        .then((response) => {
          this.overlay = false;
          response.data.forEach((element) => {
            if (
              this.$store.state.usuario.id_consultorio == element.id_consultorio
            ) {
              this.consultorios.push(element);
            }
          });
        })
        .catch((error) => {
          this.overlay = false;
          console.log(error);
          this.alerta("Sucedio algún error", "error");
        });
    },
    nuevo() {
      this.flag = -1;
      this.dialog = true;
    },
    cerrarDialog() {
      this.dialog = false;
      this.$refs.form.reset();
    },
    guardarRegistro() {
      if (this.nombre && this.direccion) {
        this.overlay = true;
        axios
          .post("api/Consultorios/Crear", {
            nombre: this.nombre,
            direccion: this.direccion,
          })
          .then(() => {
            this.dialog = false;
            this.overlay = false;
            this.$refs.form.reset();
            this.limpiarVariables();
            this.superUsuario();
            this.alerta("Registro creado con éxito", "success");
          })
          .catch((error) => {
            this.overlay = false;
            console.log(error);
            this.alerta("Sucesio algún error", "error");
          });
      } else {
        this.$refs.form.validate();
      }
    },
    editarItem(item) {
      this.flag = 1;
      this.id_consultorio = item.id_consultorio;
      this.nombre = item.nombre;
      this.direccion = item.direccion;
      this.dialog = true;
    },
    actualizarRegistro() {
      if (this.nombre && this.direccion) {
        this.overlay = true;
        axios
          .put("api/Consultorios/Actualizar", {
            id_consultorio: this.id_consultorio,
            nombre: this.nombre,
            direccion: this.direccion,
          })
          .then(() => {
            this.dialog = false;
            this.overlay = false;
            this.$refs.form.reset();
            this.limpiarVariables();
            this.superUsuario();
            this.alerta("Registro actualizado con éxito", "success");
          })
          .catch((error) => {
            this.overlay = false;
            console.log(error);
            this.alerta("Sucedio algún error", "error");
          });
      } else {
        this.$refs.form.validate();
      }
    },
    limpiarVariables() {
      this.id_consultorio = 0;
      this.nombre = "";
      this.direccion = "";
      this.$refs.form.resetValidation();
    },
    alerta(texto, tipo_alerta) {
      this.$swal({
        toast: true,
        position: "top-end",
        title: texto,
        icon: tipo_alerta,
        showConfirmButton: false,
        timer: 5000,
        backdrop: false,
      });
    },
  },
};
</script>