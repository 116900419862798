<template>
  <v-row no-gutters justify="center" class="fill-height">
    <v-col cols="auto">
      <!-- breadcrumbs -->
      <v-col cols="12">
        <v-breadcrumbs
          :items="itemsBreadcrumbs"
          divider="->"
          large
          class="pa-0"
        ></v-breadcrumbs>
      </v-col>
      <!-- mensaje llamada -->
      <v-col cols="12">
        <div class="text-justify red--text text--lighten-1 subtitle">
          Estimado solicitante: usted recibirá una llamada de su médico asignado
          dentro de las próximas 72 horas hábiles, por favor estar pendiente a
          la llamada, de lo contrario se perderá la cita. <br />
          <br />
          Nota: Estará recibiendo un correo como comprobante de su cita, de no
          visualizar el correo en su *Bandeja de entrada* favor revisar en
          *Correo no deseado o Spam* y marcarlo como *Correo no deseado o Spam*.
          <v-icon @click="abrirDialogAyuda()"
            >fa-solid fa-circle-question</v-icon
          >
        </div>
      </v-col>
      <!-- detalles -->
      <v-col cols="12">
        <v-card width="500" elevation="0" class="mx-auto">
          <!-- detalles cita/medico -->
          <v-col cols="12" class="px-3" v-if="false">
            <v-card max-width="1000">
              <v-toolbar color="primary" dark>
                <v-card-title style="color: primary">Médico</v-card-title>
              </v-toolbar>
              <v-card-text class="pa-0">
                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Fecha de la cita</v-list-item-title>
                      <p class="mb-0">{{ fecha_cita }}</p>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Nombre</v-list-item-title>
                      <p class="mb-0">{{ nombre_medico }}</p>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="false">
                    <v-list-item-content>
                      <v-list-item-title>Correo</v-list-item-title>
                      <p class="mb-0">{{ correo_medico }}</p>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Consultorio</v-list-item-title>
                      <p class="mb-0">{{ direccion_consultorio }}</p>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- detalles paciente(s) -->
          <v-col cols="12" class="px-3">
            <v-card max-width="700">
              <v-toolbar color="primary" dark>
                <v-card-title>Paciente</v-card-title>
              </v-toolbar>
              <v-card-text>
                <v-list dense v-for="(item, index) in itemCita" :key="index">
                  <v-list-item-title class="font-weight-bold"
                    ># Paciente {{ index + 1 }}</v-list-item-title
                  >
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>UID</v-list-item-title>
                      <p class="mb-0">{{ item.uid }}</p>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Nombre</v-list-item-title>
                      <p class="mb-0">
                        {{ item.nombre + " " + item.apellido }}
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Pasaporte</v-list-item-title>
                      <p class="mb-0">{{ item.pasaporte }}</p>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Numero caso</v-list-item-title>
                      <p class="mb-0">{{ item.n_caso }}</p>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Telefonos</v-list-item-title>
                      <p class="mb-0">
                        {{
                          item.telefono_n1 +
                          ", " +
                          item.telefono_n2 +
                          ", " +
                          item.telefono_n3
                        }}
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Dirección</v-list-item-title>
                      <p class="mb-0">{{ item.direccion }}</p>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Fecha de nacimiento</v-list-item-title>
                      <p class="mb-0">{{ item.fecha_nacimiento }}</p>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Correo</v-list-item-title>
                      <p class="mb-0">{{ item.correo }}</p>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-card>
      </v-col>
    </v-col>

    <v-dialog v-model="dialogAyuda" width="800">
      <v-card>
        <v-card-title>Ruta de Correo no deseado o Spam</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-img
                :src="require('@/assets/ejemplo1.png')"
                contain
                height="650"
              ></v-img>
            </v-col>
            <v-col cols="6">
              <v-img
                :src="require('@/assets/ejemplo2.png')"
                contain
                height="650"
              ></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import axios from "axios";
import moment from "moment";

export default {
  data: () => ({
    itemCita: [],
    //detalles del paciente
    nombre: "",
    apellido: "",
    pasaporte: "",
    n_caso: "",
    telefono_n1: "",
    telefono_n2: "",
    telefono_n3: "",
    direccion: "",
    fecha_nacimiento: "",
    correo: "",
    //detalles de la cita y medico
    fecha_cita: "",
    nombre_medico: "",
    correo_medico: "",
    itemsConsultorio: [],
    nombre_consultorio: "",
    direccion_consultorio: "",
    detalles_cita: [],
    //otras variables
    itemsBreadcrumbs: [
      {
        text: "Detalles de cita",
        disabled: false,
        href: "",
      },
      {
        text: "Regresar al inicio",
        disabled: false,
        href: "/",
      },
    ],
    htmlContent: "",
    dialogAyuda: false,
  }),
  created() {
    //this.listarDetalles();
  },
  methods: {
    listarDetalles() {
      this.itemCita = this.$route.params.data;

      this.asignarDatosMedico();
    },
    asignarDatosMedico() {
      axios
        .get("api/Medicos/ListarMedicoXPaciente/" + this.itemCita[0].id_medico)
        .then((response) => {
          this.nombre_medico = response.data[0].nombre;
          this.correo_medico = response.data[0].correo;
          this.nombre_consultorio = response.data[0].nombre_consultorio;
          this.direccion_consultorio = response.data[0].direccion_consultorio;
          
          this.enviarCorreoMedico();
          this.asignarDatosPaciente();
        });
    },
    asignarDatosPaciente() {
      this.itemCita.forEach((element) => {
        this.nombre = element.nombre;
        this.apellido = element.apellido;
        this.pasaporte = element.pasaporte;
        this.n_caso = element.n_caso;
        this.telefono_n1 = element.telefono_n1;
        this.telefono_n2 = element.telefono_n2;
        this.telefono_n3 = element.telefono_n3;
        this.direccion = element.direccion;
        this.fecha_nacimiento = this.formatoFecha(
          element.fecha_nacimiento_paciente
        );
        this.correo = element.correo;
        this.fecha_cita = element.fecha_cita;

        this.enviarCorreoPaciente(
          element.nombre,
          element.apellido,
          element.correo
        );
      });
    },
    formatoFecha(fecha) {
      return moment(fecha).format("DD-MM-YYYY");
    },
    enviarCorreoPaciente(nombre, apellido, correo) {
      let header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "api-key":
          "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",
      };
      let configuracion = {
        headers: header,
      };

      axios
        .post(
          "https://api.sendinblue.com/v3/smtp/email",
          {
            sender: { name: "Doctor Visa", email: "citas@doctorvisa.org" },
            to: [{ email: correo, name: nombre + " " + apellido }],
            subject: "CITA PARA EXAMEN MEDICO",
            templateId: 3,
            params: { nombre_medico: this.nombre_medico }
          },
          configuracion
        )
        .then((response) => {
          //console.log("CORREO PACIENTE ENVIADO");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    enviarCorreoMedico() {
      let header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "api-key":
          "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",
      };
      let configuracion = {
        headers: header,
      };
      let plantilla =
        "<html><body><h2>NOTIFICACIÓN DE CITA(S) PROGRAMADA(S)</h2><table border='1'><tr><th>Nombre</th><th>Pasaporte</th><th>Numero de caso</th><th>Teléfonos</th><th>Dirección</th><th>Fecha de nacimiento</th><th>Correo</th><th>Fecha de la cita</th></tr>";
      this.itemCita.forEach((element) => {
        if (this.htmlContent == "") {
          this.htmlContent =
            plantilla.substr(0, 234) +
            " <tr><td>" +
            element.nombre +
            " " +
            element.apellido +
            "</td><td>" +
            element.pasaporte +
            "</td><td>" +
            element.n_caso +
            "</td><td>" +
            element.telefono_n1 +
            " ," +
            element.telefono_n2 +
            " ," +
            element.telefono_n3 +
            "</td><td>" +
            element.direccion +
            "</td><td>" +
            element.fecha_nacimiento +
            "</td><td>" +
            element.correo +
            "</td><td>" +
            element.fecha_cita +
            "</td></tr> " +
            plantilla.substr(235, plantilla.length) +  "</table></body></html>" +
            "<style>table {font-family: arial, sans-serif;border-collapse: collapse;width: 100%;}td, th {border: 0px solid #dddddd;text-align: left;padding: 8px;}tr:nth-child(even) {background-color: #dddddd;}</style>";
        } else {
          this.htmlContent =
            this.htmlContent.substr(0, 234) +
            " <tr><td>" +
            element.nombre +
            " " +
            element.apellido +
            "</td><td>" +
            element.pasaporte +
            "</td><td>" +
            element.n_caso +
            "</td><td>" +
            element.telefono_n1 +
            " ," +
            element.telefono_n2 +
            " ," +
            element.telefono_n3 +
            "</td><td>" +
            element.direccion +
            "</td><td>" +
            element.fecha_nacimiento +
            "</td><td>" +
            element.correo +
            "</td><td>" +
            element.fecha_cita +
            "</td></tr> " +
            this.htmlContent.substr(235, this.htmlContent.length) + "</table></body></html>" +
            "<style>table {font-family: arial, sans-serif;border-collapse: collapse;width: 100%;}td, th {border: 0px solid #dddddd;text-align: left;padding: 8px;}tr:nth-child(even) {background-color: #dddddd;}</style>";
        }
      });

      axios
        .post(
          "https://api.sendinblue.com/v3/smtp/email",
          {
            sender: { name: "Doctor Visa", email: "citas@doctorvisa.org" },
            to: [{ email: this.correo_medico, name: this.nombre_medico }],
            htmlContent: this.htmlContent,
            subject: "CITA PARA EXAMEN MEDICO",
          },
          configuracion
        )
        .then((response) => {
          //console.log("CORREO MEDICO ENVIADO");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    abrirDialogAyuda() {
      this.dialogAyuda = true;
    },
  },
};
</script>

<style>
.textoPequeño {
  font-size: 10px;
}
.textoGrande {
  font-size: 50px;
}
</style>