<template>
  <v-row no-gutters class="fill-height">
    <v-col>
      <v-data-table
        :headers="headers"
        :items="pacientes"
        class="elevation-1"
        :search="busqueda"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Pacientes</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="busqueda"
              label="Busqueda"
              outlined
              dense
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="800px" persistent>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ tituloDialog }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-form ref="form" v-model="validForm">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="uid"
                            label="UID"
                            outlined
                            readonly
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="nombre"
                            label="Nombre"
                            outlined
                            readonly
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="apellido"
                            label="Apellido"
                            outlined
                            readonly
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="pasaporte"
                            label="Pasaporte"
                            outlined
                            readonly
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="n_caso"
                            label="Numero de caso"
                            outlined
                            readonly
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="telefono_n1"
                            label="Teléfono 1"
                            outlined
                            readonly
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="telefono_n2"
                            label="Teléfono 2"
                            outlined
                            readonly
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="telefono_n3"
                            label="Teléfono 3"
                            outlined
                            readonly
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="fecha_nacimiento"
                            label="Fecha de nacimiento"
                            outlined
                            readonly
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-textarea
                            v-model="direccion"
                            label="Dirección"
                            outlined
                            auto-grow
                            row-height="1"
                            readonly
                            dense
                            hide-details
                          ></v-textarea>
                        </v-col>
                        <v-col cols="12">
                          <v-textarea
                            v-model="correo"
                            label="Correo"
                            outlined
                            auto-grow
                            row-height="1"
                            readonly
                            dense
                            hide-details
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="cerrarDialog()">
                    Cerrar
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    :disabled="!validForm"
                    text
                    @click="guardarRegistro()"
                    v-if="flag === -1"
                  >
                    Guardar
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    :disabled="!validForm"
                    text
                    v-if="flag === 1 && false"
                  >
                    Actualizar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                @click="editarItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                fa-solid fa-eye
              </v-icon>
            </template>
            <span>Ver</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.fecha_nacimiento`]="{ item }">
          <span>{{ formatoFecha(item.fecha_nacimiento) }}</span>
        </template>
      </v-data-table>
      <v-snackbar
        v-model="mensaje"
        timeout="5000"
        :color="mensajeColor"
        elevation="24"
        >{{ mensajeTexto }}</v-snackbar
      >
    </v-col>

    <v-overlay v-model="overlay">
      <lottie-player
        autoplay
        loop
        mode="normal"
        src="https://assets6.lottiefiles.com/packages/lf20_uwR49r.json"
        style="width: 200px"
      >
      </lottie-player>
    </v-overlay>
  </v-row>
</template>
<script>
import axios from "axios";
import moment from "moment";
import "@lottiefiles/lottie-player";

export default {
  data: () => ({
    busqueda: "",
    dialog: false,
    dialogEliminar: false,
    headers: [
      {
        text: "Nombre",
        value: "nombre",
      },
      {
        text: "Apellido",
        value: "apellido",
      },
      {
        text: "Pasaporte",
        value: "pasaporte",
      },
      {
        text: "fecha de nacimiento",
        value: "fecha_nacimiento",
      },
      {
        text: "Médico",
        value: "nombre_medico",
      },
      { text: "Opciones", value: "actions", sortable: false },
    ],
    pacientes: [],
    id_paciente: 0,
    uid: "",
    nombre: "",
    apellido: "",
    pasaporte: "",
    n_caso: "",
    telefono_n1: "",
    telefono_n2: "",
    telefono_n3: "",
    fecha_nacimiento: "",
    direccion: "",
    correo: "",
    //variables varios
    validForm: false,
    flag: -1,
    requerido: [(v) => !!v || "El campo es obligatorio"],
    mensaje: false,
    mensajeTexto: "",
    mensajeColor: "primary",
    overlay: false,
  }),
  computed: {
    tituloDialog() {
      return this.flag === -1 ? "Nuevo Registro" : "Editar Registro";
    },
  },

  created() {
    this.superUsuario();
  },
  methods: {
    superUsuario() {
      if (this.$store.state.usuario.super_usuario == "True") {
        this.listarPacientesSuperUsuario();
      } else {
        this.listarPacientes();
      }
    },
    listarPacientesSuperUsuario() {
      this.overlay = true;
      axios
        .get("api/Pacientes/ListarJoin/" + localStorage.getItem("docto_visa_dr"))
        .then((response) => {
          this.overlay = false;
          this.pacientes = response.data;
        })
        .catch((error) => {
          this.overlay = false;
          console.log(error);
          this.mensajeTexto = "Sucedio algún error";
          this.mensajeColor = "red";
          this.mensaje = true;
        });
    },
    listarPacientes() {
      this.overlay = true;
      axios
        .get("api/Pacientes/ListarJoin/" + localStorage.getItem("docto_visa_dr"))
        .then((response) => {
          this.overlay = false;
          this.pacientes = response.data;
        })
        .catch((error) => {
          this.overlay = false;
          console.log(error);
          this.mensajeTexto = "Sucedio algún error";
          this.mensajeColor = "red";
          this.mensaje = true;
        });
    },
    cerrarDialog() {
      this.dialog = false;
      this.$refs.form.reset();
    },
    editarItem(item) {
      this.flag = 1;
      this.id_paciente = item.id_paciente;
      this.uid = item.uid;
      this.nombre = item.nombre;
      this.apellido = item.apellido;
      this.pasaporte = item.pasaporte;
      this.n_caso = item.n_caso;
      this.telefono_n1 = item.telefono_n1;
      this.telefono_n2 = item.telefono_n2;
      this.telefono_n3 = item.telefono_n3;
      this.fecha_nacimiento = this.formatoFecha(item.fecha_nacimiento);
      this.direccion = item.direccion;
      this.correo = item.correo;
      this.dialog = true;
    },
    formatoFecha(fecha) {
      return moment(fecha).format("DD-MM-YYYY");
    },
  },
};
</script>