<template>
  <v-row no-gutters class="fill-height">
    <v-col>
      <v-data-table
        :headers="headers"
        :items="roles"
        class="elevation-1"
        :search="busqueda"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Roles</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="busqueda"
              label="Busqueda"
              outlined
              dense
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="nuevo()">Nuevo</v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.condicion`]="{ item }">
          <v-chip :color="colorEstado(item.condicion)" dark>
            <div v-if="item.condicion"><span>Activo</span></div>
            <div v-else><span>Inactivo</span></div>
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                @click="editarItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-dialog v-model="dialog" max-width="800" persistent>
        <v-card>
          <v-card-title>Rol</v-card-title>
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="12" md="6" class="pr-1">
                <v-text-field
                  v-model="nombre"
                  label="Nombre"
                  outlined
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="descripcion"
                  label="Descripción"
                  outlined
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="auto" class="pb-3">
                <v-checkbox
                  v-model="condicion"
                  label="Condición"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-divider class="mt-3"></v-divider>
            <v-row>
              <v-col>
                <v-list>
                  <template v-for="(item, index) in modulos">
                    <v-list-item :key="item.nombre" :value="item">
                      <v-list-item-content>
                        <v-list-item-title>{{ item.nombre }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-checkbox
                          v-model="item.estado"
                          @click="removidos(item)"
                        ></v-checkbox>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider
                      v-if="index < modulos.length - 1"
                      :key="index"
                    ></v-divider>
                  </template>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="cerrarDialog()" color="primary" text>Cancelar</v-btn>
            <v-btn
              v-if="bandera == 1"
              :disabled="esAdministrador && esSuperUsuario"
              color="primary"
              text
              @click="crearRol()"
              >Guardar</v-btn
            >
            <v-btn
              v-if="bandera != 1"
              :disabled="esAdministrador && esSuperUsuario"
              color="primary"
              text
              @click="actualizarRol()"
              >Actualizar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
    <v-overlay v-model="overlay">
      <lottie-player
        autoplay
        loop
        mode="normal"
        src="https://assets6.lottiefiles.com/packages/lf20_uwR49r.json"
        style="width: 200px"
      >
      </lottie-player>
    </v-overlay>
  </v-row>
</template>
<script>
import axios from "axios";
import "@lottiefiles/lottie-player";

export default {
  data: () => ({
    busqueda: "",
    roles: [],
    modulos: [],
    permisos: [],
    selections: [],
    itemsRemovidos: [],
    headers: [
      {
        text: "Nombre",
        value: "nombre",
      },
      { text: "Descripcion", value: "descripcion" },
      { text: "Estado", value: "condicion", align: "center" },
      { text: "Opciones", value: "actions", sorteable: false },
    ],
    id_rol: 0,
    nombre: "",
    descripcion: "",
    condicion: false,
    dialog: false,
    bandera: 0,
    overlay: false,
  }),
  computed: {
    esAdministrador() {
      if (this.$store.state.usuario) {
        if (this.$store.state.usuario.rol == "Administrador") {
          return false;
        } else {
          return true;
        }
      }
    },
    esSuperUsuario() {
      if (this.$store.state.usuario) {
        if (this.$store.state.usuario.super_usuario == "True") {
          return false;
        } else {
          return true;
        }
      }
    },
  },
  created() {
    this.listarRoles();
  },
  methods: {
    listarRoles() {
      this.overlay = true;
      axios
        .get("api/Roles/Listar/" + this.$store.state.usuario.idUsuario)
        .then((response) => {
          this.overlay = false;
          this.roles = response.data;
        })
        .catch((error) => {
          this.overlay = false;
          console.log(error);
        });
    },
    editarItem(item) {
      this.id_rol = item.rol_id;
      this.nombre = item.nombre;
      this.descripcion = item.descripcion;
      this.condicion = item.condicion;

      this.listarModulos("editar");
      this.bandera = 2;
      this.dialog = true;
    },
    listarModulos(estatus) {
      axios.get("api/Modulos/Listar/" + this.$store.state.usuario.idUsuario)
        .then((response) => {
          response.data.forEach((element) => {
            this.modulos.push({
              id_permiso: 0,
              id_modulo: element.id_modulo,
              nombre: element.nombre,
              estado: false,
            });
          });
          if(estatus == "editar") {
            this.listarPermisosxIdRol();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listarPermisosxIdRol() {
      axios
        .get(
          "api/Permisos/Listar/" +
            this.id_rol +
            "/" +
            this.$store.state.usuario.idUsuario
        )
        .then((response) => {
          var index = 0;
          response.data.forEach((element) => {
            index = this.modulos.findIndex((object) => {
              return object.id_modulo == element.id_modulo;
            });
            this.modulos[index].estado = true;
            this.modulos[index].id_permiso = element.id_permiso;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cerrarDialog() {
      this.dialog = false;
      this.limpiar();
    },
    nuevo() {
      this.listarModulos("nuevo");
      this.bandera = 1;
      this.dialog = true;
    },
    crearRol() {
      axios
        .post("api/Roles/Crear", {
          nombre: this.nombre,
          descripcion: this.descripcion,
          condicion: true,
          permisos: this.selections,
        })
        .then((response) => {
          
          this.cerrarDialog();
          this.listarRoles();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    actualizarRol() {
      this.overlay = true;
      axios
        .put("api/Roles/Actualizar", {
          id_rol: this.id_rol,
          nombre: this.nombre,
          descripcion: this.descripcion,
          condicion: this.condicion,
        })
        .then(() => {
          this.overlay = false;
          this.actualizarPerfiles();
          this.cerrarDialog();
          this.listarRoles();
        })
        .catch((error) => {
          this.overlay = false;
          console.log(error);
        });
    },
    actualizarPerfiles() {
      //primero se eliminaran todos los permisos y luego se volveran a registrar

      this.modulos.forEach((element) => {
        if (!element.id_permiso && element.estado) {
          this.actualizarPermisos(element.id_modulo);
        }
      });

      this.itemsRemovidos.forEach((element) => {
        if (element.id_permiso) {
          this.eliminarPermisos(element.id_permiso);
        }
      });

      this.cerrarDialog();
      this.listarRoles();
    },
    eliminarPermisos(id) {
      axios
        .delete(
          "api/Permisos/Eliminar/" +
            id +
            "/" +
            this.$store.state.usuario.idUsuario
        )
        .catch((error) => {
          console.log(error);
        });
    },
    actualizarPermisos(id) {
      axios
        .post("api/Permisos/Crear", {
          id_modulo: id,
          id_rol: this.id_rol,
        })
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    },
    removidos(item) {
      if (!item.estado && item.id_permiso) {
        this.itemsRemovidos.push(item);
      } else if (item.estado) {
        this.selections.push(item);
      }
    },
    limpiar() {
      this.modulos = [];
      this.nombre = "";
      this.descripcion = "";
      this.condicion = false;
    },
    colorEstado(color) {
      if (color) {
        return "green";
      } else {
        return "red";
      }
    },
  },
};
</script>