import axios from "axios"

export default ({
    data: () => ({
        //
    }),
    metodos: {
        async obtenerToken() {
            const c_tuser = $cookies.get("c_tuser");
            if (c_tuser) {
                const result = await axios.get("api/Claves/Listar/" + c_tuser);
                return this.desencriptarToken(result.data[0].valor);
            }
        },
        desencriptarToken(token_encriptado) {
            var CryptoJS = require("crypto-js");
            var decrypted = CryptoJS.AES.decrypt(token_encriptado,"secret key devco");
            var textOriginal = decrypted.toString(CryptoJS.enc.Utf8);
            return textOriginal;

            /*var CryptoJS = require("crypto-js");
            var decrypted = CryptoJS.AES.decrypt(token_encriptado, "siie_Devc@22");
            return decrypted.toString(CryptoJS.enc.Utf8);*/
        },
    }
})