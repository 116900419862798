<template>
  <v-row no-gutters class="fill-height">
    <v-col>
      <v-data-table
        :headers="headers"
        :items="usuarios"
        class="elevation-1"
        :search="busqueda"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Usuarios</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="busqueda"
              label="Busqueda"
              outlined
              dense
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="nuevo()"
              v-if="!esAdministrador"
            >
              Nuevo
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                @click="editarItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.condicion`]="{ item }">
          <v-chip :color="colorEstado(item.condicion)" dark>
            <div v-if="item.condicion"><span>Activo</span></div>
            <div v-else><span>Inactivo</span></div>
          </v-chip>
        </template>
      </v-data-table>
    </v-col>

    <v-dialog v-model="dialog" max-width="800px" persistent>
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ tituloDialog }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="validForm">
              <v-row no-gutters>
                <v-col cols="12" md="6" class="pa-1">
                  <v-text-field
                    v-model="nombre"
                    label="Nombre"
                    outlined
                    :rules="requerido"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pa-1">
                  <v-autocomplete
                    v-model="id_consultorio"
                    :items="items_consultorio"
                    item-value="id_consultorio"
                    item-text="nombre"
                    label="Nombre consultorio"
                    outlined
                    :rules="requerido"
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" class="pa-1">
                  <v-text-field
                    v-model="usuario"
                    label="Usuario"
                    outlined
                    :rules="requerido"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pa-1">
                  <v-select
                    v-model="id_rol"
                    :items="itemsRol"
                    item-text="nombre"
                    item-value="rol_id"
                    label="Rol"
                    outlined
                    :rules="requerido"
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" class="pa-1">
                  <v-text-field
                    v-model="contraseña"
                    label="Contraseña"
                    outlined
                    type="password"
                    :rules="requerido"
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-row justify="end">
                    <v-col cols="auto" class="pa-1">
                      <v-checkbox
                        v-model="condicion"
                        label="Estado"
                        dense
                        hide-details
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="auto" class="pa-1">
                      <v-checkbox
                        v-model="super_usuario"
                        label="Super usuario"
                        v-if="!esSuperUsuario"
                        dense
                        hide-details
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6" class="pa-1">
                  <v-system-bar
                    height="30"
                    color="white"
                    v-for="(item, i) in item_validar_pass"
                    :key="i"
                  >
                    <v-icon>fas fa-info-circle</v-icon>
                    <div>{{ item.texto }}</div>
                  </v-system-bar>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cerrarDialog()">
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            :disabled="esAdministrador"
            text
            @click="guardarRegistro()"
            v-if="flag === -1"
          >
            Guardar
          </v-btn>
          <v-btn
            color="blue darken-1"
            :disabled="esAdministrador"
            text
            @click="actualizarRegistro()"
            v-if="flag === 1"
          >
            Actualizar
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-overlay v-model="overlayDialog">
        <lottie-player
          autoplay
          loop
          mode="normal"
          src="https://assets6.lottiefiles.com/packages/lf20_uwR49r.json"
          style="width: 200px"
        >
        </lottie-player>
      </v-overlay>
    </v-dialog>

    <v-overlay v-model="overlay">
      <lottie-player
        autoplay
        loop
        mode="normal"
        src="https://assets6.lottiefiles.com/packages/lf20_uwR49r.json"
        style="width: 200px"
      >
      </lottie-player>
    </v-overlay>
  </v-row>
</template>
<script>
import axios from "axios";
import "@lottiefiles/lottie-player";

export default {
  data: () => ({
    busqueda: "",
    dialog: false,
    headers: [
      {
        text: "Nombre",
        value: "nombre",
      },
      { text: "Usuario", value: "usuario" },
      { text: "Consultorio", value: "nombre_consultorio" },
      { text: "Rol", value: "nombre_rol" },
      { text: "Estado", value: "condicion", align: "center" },
      { text: "Opciones", value: "actions", align: "center", sortable: false },
    ],
    usuarios: [],
    itemsRol: [],
    items_consultorio: [],
    id_usuario: 0,
    id_rol: 0,
    id_consultorio: 0,
    nombre: "",
    usuario: "",
    contraseña: "",
    actPassword: false,
    passwordAnt: "",
    condicion: false,
    super_usuario: false,
    //variables varios
    validForm: false,
    flag: -1,
    requerido: [(v) => !!v || "El campo es obligatorio"],
    overlay: false,
    overlayDialog: false,
    item_validar_pass: [
      { texto: "Mínimo 6 caracteres" },
      { texto: "Un carácter en mayúscula" },
      { texto: "Un carácter especial" },
      { texto: "Un número" },
    ],
  }),
  computed: {
    tituloDialog() {
      return this.flag === -1 ? "Nuevo Registro" : "Editar Registro";
    },
    esAdministrador() {
      if (this.$store.state.usuario) {
        if (this.$store.state.usuario.rol == "Administrador") {
          return false;
        } else {
          return true;
        }
      }
    },
    esSuperUsuario() {
      if (this.$store.state.usuario) {
        if (this.$store.state.usuario.super_usuario == "True") {
          return false;
        } else {
          return true;
        }
      }
    },
  },
  created() {
    this.superUsuario();
    this.listarRoles();
    this.listarConsultorios();
  },
  methods: {
    superUsuario() {
      if (this.$store.state.usuario.super_usuario == "True") {
        this.listarUsuariosSuperUsuario();
      } else {
        this.listarUsuarios();
      }
    },
    listarUsuariosSuperUsuario() {
      this.overlay = true;
      axios
        .get("api/Usuarios/Listar/" + this.$store.state.usuario.idUsuario)
        .then((response) => {
          this.overlay = false;
          this.usuarios = response.data;
        })
        .catch(() => {
          this.overlay = false;
          this.alerta("Sucedio algún error", "error");
        });
    },
    listarUsuarios() {
      this.overlay = true;
      axios
        .get("api/Usuarios/Listar/" + this.$store.state.usuario.idUsuario)
        .then((response) => {
          this.overlay = false;
          response.data.forEach((element) => {
            if (
              this.$store.state.usuario.id_consultorio == element.id_consultorio
            ) {
              this.usuarios.push(element);
            }
          });
        })
        .catch((error) => {
          this.overlay = false;
          console.log(error);
          this.alerta("Sucedio algún error", "error");
        });
    },
    listarRoles() {
      axios
        .get("api/Roles/ListarActivos/" + this.$store.state.usuario.idUsuario)
        .then((response) => {
          this.itemsRol = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.alerta("Sucedio algún error", "error");
        });
    },
    listarConsultorios() {
      axios
        .get("api/Consultorios/Listar/" + this.$store.state.usuario.idUsuario)
        .then((response) => {
          this.items_consultorio = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.alerta("Sucedio algún error", "error");
        });
    },
    nuevo() {
      this.flag = -1;
      this.dialog = true;
    },
    cerrarDialog() {
      this.dialog = false;
      this.$refs.form.reset();
    },
    guardarRegistro() {
      let i = 0;
      let j = 0;
      var format_special = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      var format_number = /^\d$/;
      var format_uppCase = /^[A-Z]*$/;
      var letter_mayus = false;
      var letter_num = false;

      //CARACTER ESPECIAL
      if (!format_special.test(this.contraseña)) {
        return this.alerta(
          "La contraseña no cumple los requisitos. (caracter especial)",
          "info"
        );
      }

      if (this.contraseña.length < 8) {
        return this.alerta(
          "La contraseña no cumple los requisitos. (muy corta)",
          "info"
        );
      }

      while (j < this.contraseña.length) {
        let letra = this.contraseña.charAt(j);
        if (format_number.test(letra)) {
          letter_num = true;
        }
        j++;
      }

      if (!letter_num) {
        return this.alerta(
          "La contraseña no cumple los requisitos. (falta un numero)",
          "info"
        );
      }

      while (i < this.contraseña.length) {
        let letra = this.contraseña.charAt(i);
        if (format_uppCase.test(letra)) {
          letter_mayus = true;
        }
        i++;
      }

      if (!letter_mayus) {
        return this.alerta(
          "La contraseña no cumple los requisitos. (falta una letra mayuscula)",
          "info"
        );
      }

      if (
        this.nombre &&
        this.id_consultorio &&
        this.usuarios &&
        this.contraseña
      ) {
        Promise.all([this.verificarUsuario()]).then((response) => {
          if (response[0].data == 0) {
            this.overlayDialog = true;
            axios
              .post("api/Usuarios/Crear", {
                id_rol: this.id_rol,
                id_consultorio: this.id_consultorio,
                nombre: this.nombre,
                usuario: this.usuario,
                password: this.contraseña,
                condicion: this.condicion,
                super_usuario: this.super_usuario,
              })
              .then(() => {
                this.dialog = false;
                this.overlayDialog = false;
                this.$refs.form.reset();
                this.limpiarVariables();
                this.superUsuario();
                this.alerta("Registro creado con éxito", "success");
              })
              .catch((error) => {
                this.overlayDialog = false;
                this.alerta("Sucedio algún error", "error");
                console.log(error);
              });
          } else {
            this.alerta("Ya existe nombre de usuario.", "info");
          }
        });
      } else {
        this.$refs.form.validate();
      }
    },
    editarItem(item) {
      this.flag = 1;
      this.id_usuario = item.id_usuario;
      this.id_rol = item.id_rol;
      this.id_consultorio = item.id_consultorio;
      this.nombre = item.nombre;
      this.usuario = item.usuario;
      this.contraseña = item.password_hash;
      this.passwordAnt = item.password_hash;
      this.condicion = item.condicion;
      this.super_usuario = item.super_usuario;
      this.dialog = true;
    },
    actualizarRegistro() {
      let i = 0;
      let j = 0;
      var format_special = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      var format_number = /^\d$/;
      var format_uppCase = /^[A-Z]*$/;
      var letter_mayus = false;
      var letter_num = false;

      if (this.contraseña != this.passwordAnt) {
        this.actPassword = true;

        if (!format_special.test(this.contraseña)) {
          return this.alerta(
            "La contraseña no cumple los requisitos. (caracter especial)",
            "info"
          );
        }

        if (this.contraseña.length < 8) {
          return this.alerta(
            "La contraseña no cumple los requisitos. (muy corta)",
            "info"
          );
        }

        while (j < this.contraseña.length) {
          let letra = this.contraseña.charAt(j);
          if (format_number.test(letra)) {
            letter_num = true;
          }
          j++;
        }

        if (!letter_num) {
          return this.alerta(
            "La contraseña no cumple los requisitos. (falta un numero)",
            "info"
          );
        }

        while (i < this.contraseña.length) {
          let letra = this.contraseña.charAt(i);
          if (format_uppCase.test(letra)) {
            letter_mayus = true;
          }
          i++;
        }

        if (!letter_mayus) {
          return this.alerta(
            "La contraseña no cumple los requisitos. (falta una letra mayuscula)",
            "info"
          );
        }
      }

      this.usuarios.forEach((element) => {
        if (this.usuario == element.usuario) {
          if (this.$store.state.usuario.idUsuario != element.id_usuario) {
            return this.alerta("Ya existe el usuario con este nombre.", "info");
          }
        }
      });

      if (
        this.nombre &&
        this.id_consultorio &&
        this.usuarios &&
        this.contraseña
      ) {
        this.overlayDialog = true;
        axios
          .put("api/Usuarios/Actualizar", {
            id_usuario: this.id_usuario,
            id_rol: this.id_rol,
            id_consultorio: this.id_consultorio,
            nombre: this.nombre,
            usuario: this.usuario,
            password: this.contraseña,
            act_password: this.actPassword,
            condicion: this.condicion,
            super_usuario: this.super_usuario,
          })
          .then(() => {
            this.dialog = false;
            this.overlayDialog = false;
            this.limpiarVariables();
            this.superUsuario();
            this.alerta("Registro actualizado con éxito", "success");
          })
          .catch(() => {
            this.overlayDialog = false;
            this.alerta("Sucedio algún error", "error");
          });
      } else {
        this.$refs.form.validate();
      }
    },
    limpiarVariables() {
      this.$refs.form.reset();
      this.id_usuario = 0;
      this.id_rol = "";
      this.id_consultorio = 0;
      this.nombre = "";
      this.usuario = "";
      this.contraseña = "";
      this.condicion = false;
      this.passwordAnt = "";
      this.actPassword = false;
      this.super_usuario = false;
      this.usuarios = [];
    },
    colorEstado(estado) {
      if (estado) {
        return "success";
      } else {
        return "red";
      }
    },
    alerta(texto, tipo_alerta) {
      this.$swal({
        toast: true,
        position: "top-end",
        title: texto,
        icon: tipo_alerta,
        showConfirmButton: false,
        timer: 5000,
        backdrop: false,
      });
    },
    verificarUsuario() {
      return axios.get("api/Usuarios/VerificarUsuario/" + this.usuario);
    },
  },
};
</script>