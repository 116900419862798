<template>
  <v-container fluid style="height: 100%">
    <v-data-table
      :headers="headers"
      :items="citas"
      :items-per-page="-1"
      class="elevation-1"
      group-by="nombre_medico"
      show-group-by
      sort-by="nombre_medico"
    >
      <!-- TOP DATA TABLE -->
      <template v-slot:top>
        <v-toolbar flat>
          <v-menu :close-on-content-click="false" offset-y width="1000">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" right outlined class="mx-1">
                REPORTE GENERAL
                <v-icon small color="primary">fa-solid fa-filter</v-icon>
              </v-btn>
            </template>
            <v-card width="600">
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols="12" md="5" class="pa-1">
                    <v-text-field
                      v-model="fecha_desde"
                      label="Fecha de cita desde"
                      type="date"
                      hide-details
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="5" class="pa-1">
                    <v-text-field
                      v-model="fecha_hasta"
                      label="Fecha de cita hasta"
                      type="date"
                      hide-details
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2" class="pa-1">
                    <v-btn
                      color="primary"
                      @click="listarCitasEntreFechasGENERAL()"
                    >
                      Buscar
                    </v-btn>
                  </v-col>
                  <v-col cols="auto">
                    <h2 class="mt-3">Total de citas: {{ total_citas }}</h2>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn
                  @click="DescargarExcelGeneral()"
                  color="info"
                  :disabled="citas.length == 0"
                  >Descargar</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-menu>

          <v-menu :close-on-content-click="false" offset-y width="1000" v-if="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" right outlined class="mx-1">
                ESTADISTICOS CITAS
                <v-icon small color="primary">fa-solid fa-filter</v-icon>
              </v-btn>
            </template>
            <v-card width="600">
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols="12" md="5" class="pa-1">
                    <v-text-field
                      v-model="fecha_desde"
                      label="Fecha de cita desde"
                      type="date"
                      hide-details
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="5" class="pa-1">
                    <v-text-field
                      v-model="fecha_hasta"
                      label="Fecha de cita hasta"
                      type="date"
                      hide-details
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2" class="pa-1">
                    <v-btn color="primary" @click="listarCitasEntreFechas()">
                      Buscar
                    </v-btn>
                  </v-col>
                  <v-col cols="auto">
                    <h2 class="mt-3">Total de citas: {{ total_citas }}</h2>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn
                  @click="DescargarExcelEstadisticos()"
                  color="info"
                  :disabled="citas.length == 0"
                  >Descargar</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-toolbar>
      </template>
      <!-- HEADERS GROUP -->
      <template
        v-slot:[`group.header`]="{ group, headers, toggle, isOpen, items }"
      >
        <td :colspan="headers.length">
          <v-btn @click="toggle" x-small icon :ref="group">
            <v-icon v-if="isOpen">mdi-minus</v-icon>
            <v-icon v-else>mdi-plus</v-icon>
          </v-btn>
          <span class="mx-5 font-weight-bold">{{ group }}</span>
          <span class="mx-5 font-weight-bold">
            - Cantidad de citas: {{ items.length }}</span
          >
        </td>
      </template>

      <template
        v-slot:[`item.actions_r`]="{ item }"
        v-if="
          $store.state.usuario.nombre == 'Dr. Roberto Zelaya' ||
          $store.state.usuario.nombre == 'User Devco'
        "
      >
        <v-tooltip right color="blue darken-4">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-if="item.fue_movido" v-bind="attrs" v-on="on">
              fa-solid fa-arrow-right-arrow-left
            </v-icon>
          </template>
          <span
            >Cita reagendada: se cambio de fecha que el paciente asigno</span
          >
        </v-tooltip>
      </template>

      <template
        v-slot:[`item.actions_gi`]="{ item }"
        v-if="
          $store.state.usuario.nombre == 'Dr. Roberto Zelaya' ||
          $store.state.usuario.nombre == 'User Devco'
        "
      >
        <v-tooltip right color="blue darken-4">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="item.es_grupal_individual == 0"
              v-bind="attrs"
              v-on="on"
            >
              fa-solid fa-user
            </v-icon>
            <v-icon v-else v-bind="attrs" v-on="on"> fa-solid fa-users </v-icon>
          </template>
          <span v-if="item.es_grupal_individual == 0">Es cita individual</span>
          <span v-else>Es cita grupal</span>
        </v-tooltip>
      </template>

      <template
        v-slot:[`item.actions_cv`]="{ item }"
        v-if="
          $store.state.usuario.nombre == 'Dr. Roberto Zelaya' ||
          $store.state.usuario.nombre == 'User Devco'
        "
      >
        <v-tooltip right color="blue darken-4">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-if="item.cita_vencida == 1" v-bind="attrs" v-on="on">
              fa-solid fa-circle-exclamation
            </v-icon>
          </template>
          <span>Cita vencida</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.fecha_creacion`]="{ item }">
        <div>
          <span>{{ formatoFecha(item.fecha_creacion) }}</span>
        </div>
      </template>

      <template v-slot:[`item.fecha_nacimiento_paciente`]="{ item }">
        <div>
          <span>{{ calcularEdad(item.fecha_nacimiento_paciente) }}</span>
        </div>
      </template>

      <template v-slot:[`item.nombre_paciente`]="{ item }">
        <div>
          <span>{{ item.nombre_paciente + " " + item.apellido_paciente }}</span>
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
var XLSX = require("xlsx");

export default {
  data: () => ({
    headers: [
      {
        text: "Opc",
        value: "actions_r",
        groupable: false,
        width: "10",
        sortable: false,
      },
      {
        text: "Opc",
        value: "actions_gi",
        groupable: false,
        width: "10",
        sortable: false,
      },
      {
        text: "Opc",
        value: "actions_cv",
        groupable: false,
        width: "10",
        sortable: false,
      },
      { text: "Fecha creación", value: "fecha_creacion", groupable: false },
      { text: "Médico", value: "nombre_medico" },
      {
        text: "Número de caso",
        value: "n_caso_paciente",
        groupable: false,
        width: "50",
      },
      { text: "Edad", value: "fecha_nacimiento_paciente", groupable: false },
      { text: "Paciente", value: "nombre_paciente", groupable: false },
      { text: "Pasaporte", value: "pasaporte_paciente", groupable: false },
    ],
    citas: [],
    fecha_desde:
      new Date().getFullYear() +
      "-" +
      String(new Date().getMonth() + 1).padStart(2, "0") +
      "-" +
      String(new Date().getDate()).padStart(2, "0"),
    fecha_hasta:
      new Date().getFullYear() +
      "-" +
      String(new Date().getMonth() + 1).padStart(2, "0") +
      "-" +
      String(new Date().getDate()).padStart(2, "0"),
    total_citas: 0,
    items_tipos: [],
    count_dr_zelaya: 1,
    count_dr_guillen: 1,
    count_dr_herrera: 1,
    count_dr_chica: 0,
    count_dr_bichara: 1,
    count_dr_mancia: 1,
    count_reprogramados_dr_zelaya: 1,
    count_reprogramados_dr_guillen: 1,
    count_reprogramados_dr_herrera: 1,
    count_reprogramados_dr_chica: 0,
    count_reprogramados_dr_bichara: 1,
    count_reprogramados_dr_mancia: 1,
    count_vencidos_dr_zelaya: 1,
    count_vencidos_dr_guillen: 1,
    count_vencidos_dr_herrera: 1,
    count_vencidos_dr_chica: 0,
    count_vencidos_dr_bichara: 1,
    count_vencidos_dr_mancia: 1,
  }),
  created() {
    this.listarCitasEntreFechasGENERAL();
  },
  methods: {
    listarCitasEntreFechasGENERAL() {
      axios
        .get(
          "api/Citas/ListarTodos/" + this.fecha_desde + "/" + this.fecha_hasta
        )
        .then((response) => {
          this.citas = response.data;
          this.total_citas = this.citas.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    calcularEdad(item) {
      var hoy = new Date();
      var cumpleanios = new Date(item);
      var edad = hoy.getFullYear() - cumpleanios.getFullYear();
      var m = hoy.getMonth() - cumpleanios.getMonth();
      if (m < 0 || (m === 0 && hoy.getDate() < cumpleanios.getDate())) {
        edad--;
      }
      return edad;
    },
    formatoFecha(fecha) {
      return moment(new Date(fecha)).format("YYYY-MM-DD");
    },
    DescargarExcelGeneral() {
      const excel_data = [];
      this.citas.forEach((element) => {
        excel_data.push({
          "FECHA CREACIÓN": element.fecha_creacion,
          "FECHA CITA": this.formatoFecha(element.fecha_cita),
          "NÚMERO DE CASO": element.n_caso_paciente,
          MÉDICO: element.nombre_medico,
          EDAD: this.calcularEdad(element.fecha_nacimiento_paciente),
          NOMBRE: element.nombre_paciente + " " + element.apellido_paciente,
          PASAPORTE: element.pasaporte_paciente,
          REPROGRAMADA: element.fue_movido ? "SI" : "NO",
          "GRUPAL O INDIVIDUAL": element.es_grupal_individual
            ? "GRUPAL"
            : "INDIVIDUAL",
          "CITA VENCIDA": element.cita_vencida ? "SI" : "NO",
        });
      });

      let data = XLSX.utils.json_to_sheet(excel_data);
      const workbook = XLSX.utils.book_new();
      const filename = "Citas " + this.fecha_desde + " " + this.fecha_hasta;
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    DescargarExcelEstadisticos() {
      const excel_data = [];
      const estadisticas = [];

      this.citas.forEach((element) => {
        switch (element.nombre_medico) {
          case "Dr. Roberto Zelaya":
            var index = excel_data.findIndex(
              (obj) => obj.Nombre === "Dr. Roberto Zelaya"
            );
            if (index == -1) {
              excel_data.push({
                Nombre: "Dr. Roberto Zelaya",
                Reprogramados: element.fue_movido ? this.count_reprogramados_dr_zelaya ++ : this.count_reprogramados_dr_zelaya,
                Vecidos: element.cita_vencida ? this.count_vencidos_dr_zelaya ++ : this.count_vencidos_dr_zelaya,
                Total: this.count_dr_zelaya ++
              });
            } else {
                excel_data[index].Reprogramados = element.fue_movido ? this.count_reprogramados_dr_zelaya ++ : this.count_reprogramados_dr_zelaya;
                excel_data[index].Vecidos = element.cita_vencida ? this.count_vencidos_dr_zelaya ++ : this.count_vencidos_dr_zelaya;
                excel_data[index].Total = this.count_dr_zelaya ++;
            }
            break;
          case "Dr. Juan Guillen":
            var index = excel_data.findIndex(
              (obj) => obj.Nombre === "Dr. Juan Guillen"
            );
            if (index == -1) {
              excel_data.push({
                Nombre: "Dr. Juan Guillen",
                Reprogramados: element.fue_movido ? this.count_reprogramados_dr_guillen ++ : this.count_reprogramados_dr_guillen,
                Vecidos: element.cita_vencida ? this.count_vencidos_dr_guillen ++ : this.count_vencidos_dr_guillen,
                Total: this.count_dr_guillen ++
              });
            } else {
                excel_data[index].Reprogramados = element.fue_movido ? this.count_reprogramados_dr_guillen ++ : this.count_reprogramados_dr_guillen
                excel_data[index].Vecidos = element.cita_vencida ? this.count_vencidos_dr_guillen ++ : this.count_vencidos_dr_guillen,
                excel_data[index].Total = this.count_dr_guillen ++
            }
            break;
          case "Dra. María José Herrera":
            var index = excel_data.findIndex(
              (obj) => obj.Nombre === "Dra. María José Herrera"
            );
            if (index == -1) {
              excel_data.push({
                Nombre: "Dra. María José Herrera",
                Reprogramados: element.fue_movido ? this.count_reprogramados_dr_herrera ++ : this.count_reprogramados_dr_herrera,
                Vecidos: element.cita_vencida ? this.count_vencidos_dr_herrera ++ : this.count_vencidos_dr_herrera,
                Total: this.count_dr_herrera ++
              });
            } else {
                excel_data[index].Reprogramados = element.fue_movido ? this.count_reprogramados_dr_herrera ++ : this.count_reprogramados_dr_herrera;
                excel_data[index].Vecidos = element.cita_vencida ? this.count_vencidos_dr_herrera ++ : this.count_vencidos_dr_herrera;
                excel_data[index].Total = this.count_dr_herrera ++
            }
            break;
          case "Dra. Margarita Chica":
            var index = excel_data.findIndex(
              (obj) => obj.Nombre === "Dra. Margarita Chica"
            );
            if (index == -1) {
              excel_data.push({
                Nombre: "Dra. Margarita Chica",
                Reprogramados: element.fue_movido == true ? this.count_reprogramados_dr_chica = this.count_reprogramados_dr_chica + 1 : this.count_reprogramados_dr_chica,
                Vecidos: element.cita_vencida == true ? this.count_vencidos_dr_chica = this.count_vencidos_dr_chica + 1 : this.count_vencidos_dr_chica,
                Total: this.count_dr_chica = this.count_dr_chica + 1
              });
            } else {
                excel_data[index].Reprogramados = element.fue_movido == true ? this.count_reprogramados_dr_chica = this.count_reprogramados_dr_chica + 1 : this.count_reprogramados_dr_chica;
                excel_data[index].Vecidos = element.cita_vencida == true ? this.count_vencidos_dr_chica = this.count_vencidos_dr_chica + 1 : this.count_vencidos_dr_chica;
                excel_data[index].Total = this.count_dr_chica ++
            }
            break;
          case "Dra. Frida Bichara":
            var index = excel_data.findIndex(
              (obj) => obj.Nombre === "Dra. Frida Bichara"
            );
            if (index == -1) {
              excel_data.push({
                Nombre: "Dra. Frida Bichara",
                Reprogramados: element.fue_movido ? this.count_reprogramados_dr_bichara ++ : this.count_reprogramados_dr_bichara,
                Vecidos: element.cita_vencida ? this.count_vencidos_dr_bichara ++ : this.count_vencidos_dr_bichara,
                Total: this.count_dr_bichara ++
              });
            } else {
                excel_data[index].Reprogramados = element.fue_movido ? this.count_reprogramados_dr_bichara ++ : this.count_reprogramados_dr_bichara;
                excel_data[index].Vecidos = element.cita_vencida ? this.count_vencidos_dr_bichara ++ : this.count_vencidos_dr_bichara;
                excel_data[index].Total = this.count_dr_bichara ++
            }
            break;
          case "Dra. Magdalena Mancia":
            var index = excel_data.findIndex(
              (obj) => obj.Nombre === "Dra. Magdalena Mancia"
            );
            if (index == -1) {
              excel_data.push({
                Nombre: "Dra. Magdalena Mancia",
                Reprogramados: element.fue_movido ? this.count_reprogramados_dr_mancia ++ : this.count_reprogramados_dr_mancia,
                Vecidos: element.cita_vencida ? this.count_vencidos_dr_mancia ++ : this.count_vencidos_dr_mancia,
                Total: this.count_dr_mancia++
              });
            } else {
                excel_data[index].Reprogramados = element.fue_movido ? this.count_reprogramados_dr_mancia ++ : this.count_reprogramados_dr_mancia;
                excel_data[index].Vecidos = element.cita_vencida ? this.count_vencidos_dr_mancia ++ : this.count_vencidos_dr_mancia;
                excel_data[index].Total = this.count_dr_mancia ++
            }
            break;
        }
      });

      let data = XLSX.utils.json_to_sheet(excel_data);
      const workbook = XLSX.utils.book_new();
      const filename = "Citas" + this.fecha_desde + " " + this.fecha_hasta;
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    formatoFecha(fecha) {
        return moment(fecha).format("L");
    }
  },
};
</script>