<template>
  <v-container style="height: 100%">
    <div class="display-1 font-weight-bold ma-2">ESTADISTICAS</div>
    <v-row justify="center">
      <v-col cols="auto" md="4" align-self="center">
        <v-card>
          <v-toolbar elevation="0">
            <div class="font-weight-bold title mx-auto">
              Número de visitas de pacientes
            </div>
          </v-toolbar>
          <v-card-text>
            <v-row justify="center">
              <v-col cols="auto" align-self="center">
                <v-icon color="black" large>fa-regular fa-eye</v-icon>
              </v-col>
              <v-col cols="auto">
                <div class="black--text headline">
                  {{ n_visitas_pacientes }}
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="auto" md="4" align-self="center"
        ><v-card>
          <v-toolbar elevation="0">
            <div class="font-weight-bold title mx-auto">
              Número de visitas de usuarios
            </div>
          </v-toolbar>
          <v-card-text>
            <v-row justify="center">
              <v-col cols="auto" align-self="center">
                <v-icon color="black" large>fa-regular fa-eye</v-icon>
              </v-col>
              <v-col cols="auto">
                <div class="black--text headline">{{ n_visitas_usuarios }}</div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card></v-col
      >
      <v-col cols="auto" md="4">
        <v-card>
          <v-toolbar elevation="0">
            <div class="font-weight-bold title mx-auto">
              Número de citas registradas
            </div>
          </v-toolbar>
          <v-card-text>
            <v-row justify="center">
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="fecha"
                  label="Citas registradas por fecha"
                  type="date"
                  hide-details
                  dense
                  @input="listarCitasCreadas()"
                ></v-text-field>
              </v-col>
              <v-col
                cols="auto"
                md="3"
                align-self="center"
                class="text-center pr-0"
              >
                <v-icon color="black" large
                  >fa-regular fa-calendar-check</v-icon
                >
              </v-col>
              <v-col cols="auto" md="3" align-self="center" class="pl-0">
                <div class="black--text headline">
                  {{ n_citas_registradas }}
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  data: () => ({
    fecha: "",
    n_visitas_pacientes: 0,
    n_visitas_usuarios: 0,
    n_citas_registradas: 0,
  }),
  created() {
    this.listarVisitasPacientes();
    this.listarVisitasUsuarios();
    this.listarCitasCreadas();
  },
  methods: {
    listarVisitasPacientes() {
      axios
        .get(
          "api/ContadorVisitas/Listar"
        )
        .then((response) => {
          this.n_visitas_pacientes = response.data[0].visitas_invitados;
        });
    },
    listarVisitasUsuarios() {
      axios
        .get("api/ContadorVisitas/Listar")
        .then((response) => {
          this.n_visitas_usuarios = response.data[0].visitas_usuarios;
        });
    },
    listarCitasCreadas() {
      var date = new Date();
      if (this.fecha == "") {
        this.fecha =
          date.getFullYear() +
          "-" +
          ("0" + (date.getMonth() + 1)).slice(-2) +
          "-" +
          date.getDate();
      }

      axios
        .get("api/Citas/ListarXFecha/" + this.formatoFecha(this.fecha))
        .then((response) => {
          this.n_citas_registradas = response.data;
        });
    },
    formatoFecha(fecha) {
      return moment(fecha).format("yyyy-MM-DD");
    },
  },
};
</script>