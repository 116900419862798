<template>
  <v-container>
    <v-form ref="form">
      <v-row>
        <v-col cols="12">
          <v-card class="mx-auto" width="500">
            <v-toolbar elevation="0" color="primary" dark>
              <v-card-title class="mx-auto font-weight-bold"
                >Inicio de sesión</v-card-title
              >
            </v-toolbar>
            <v-card-text>
              <v-sheet>
                <v-text-field
                  v-model="usuario"
                  label="Usuario"
                  prepend-icon="fa-solif fa-user"
                  :rules="requerido"
                ></v-text-field>
                <v-text-field
                  v-model="contraseña"
                  label="Contraseña"
                  prepend-icon="fa-solif fa-unlock"
                  :append-icon="
                    verContraseña ? 'fa-solif fa-eye' : 'fa-solid fa-eye-slash'
                  "
                  @click:append="verContraseña = !verContraseña"
                  :type="verContraseña ? 'text' : 'password'"
                  :rules="requerido"
                  @keyup.enter="iniciarSesion"
                ></v-text-field>
              </v-sheet>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn block tile color="primary" @click="iniciarSesion()"
                >Iniciar Sesión</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
    <v-snackbar v-model="snackbar" timeout="5000" :color="snackbarColor">{{
      snackbarMensaje
    }}</v-snackbar>

    <v-overlay v-model="overlay">
      <lottie-player
        autoplay
        loop
        mode="normal"
        src="https://assets6.lottiefiles.com/packages/lf20_uwR49r.json"
        style="width: 200px"
      >
      </lottie-player>
    </v-overlay>
  </v-container>
</template>

<script>
import axios from "axios";
import router from "../router";
import moment from "moment";
import "@lottiefiles/lottie-player";

export default {
  data: () => ({
    verContraseña: false,
    requerido: [(v) => !!v || "El campo es obligatorio"],
    usuario: "",
    contraseña: "",
    //otras  variables
    snackbar: false,
    snackbarColor: "primary",
    snackbarMensaje: "",
    overlay: false,
  }),
  created() {
    //
  },
  methods: {
    iniciarSesion() {
      if (this.usuario && this.contraseña) {
        this.overlay = true;
        axios
          .post("api/Usuarios/Login", {
            usuario: this.usuario,
            password: this.contraseña,
          })
          .then((response) => {
            this.overlay = false;
            this.snackbarMensaje = "Inicio de sesion exitosa";
            this.snackbar = true;
            this.$store.dispatch("guardarToken", response.data.token);
            this.verificarClaves(response.data.token);
            this.guardarMedico();
          })
          .catch((err) => {
            this.overlay = false;
            this.snackbarMensaje = "No se pudo iniciar sesión";
            this.snackbar = true;
          });
      } else {
        this.$refs.form.validate();
        this.snackbarMensaje = "Los campos son obligatorios";
        this.snackbarColor = "red";
        this.snackbar = true;
      }
    },
    guardarMedico() {
      axios.get("api/Medicos/ListarxConsultorio/" + this.$store.state.usuario.id_consultorio)
      .then((response) => {
        this.$store.dispatch("guardarMedico", response.data[0].id_medico);

        //console.log(response.data);
      });
    },
    verificarClaves(token) {
      axios
        .get("api/Claves/Verificar/" + this.$store.state.usuario.idUsuario)
        .then((response) => {
          if (response.data) {
            this.desencriptarToken(response.data[0].clave);
          } else {
            this.encriptarToken(token);
          }
        });
    },
    encriptarToken(token) {
      var CryptoJS = require("crypto-js");
      var encrypted = CryptoJS.AES.encrypt(
        token,
        "secret key devco"
      ).toString();
      this.guardarClave(encrypted);
    },
    desencriptarToken(clave_token) {
      var dateInit = new Date();
      dateInit.setMinutes(dateInit.getMinutes() + 1440);

      axios
        .put("api/Claves/ActualizarExpiracionToken", {
          id_usuario: this.$store.state.usuario.idUsuario,
          expiracion: moment(dateInit).format("YYYY/MM/DD HH:mm:ss"),
        })
        .then(() => {
          localStorage.setItem("c_tuser", clave_token);
          router.push({ name: "bienvenida" }).catch(() => {});
        });
    },
    guardarClave(token_encriptado) {
      var dateInit = new Date();
      dateInit.setMinutes(dateInit.getMinutes() + 1440);

      this.generarCodigo();

      axios
        .post("api/Claves/Crear", {
          id_usuario: this.$store.state.usuario.idUsuario,
          clave: this.codigo,
          valor: token_encriptado,
          expiracion: moment(dateInit).format("YYYY/MM/DD HH:mm:ss"),
        })
        .then(() => {
          localStorage.setItem("c_tuser", this.codigo);
          router.push({ name: "bienvenida" }).catch(() => {});
        })
        .catch((error) => {
          console.log(error);
        });
    },
    generarCodigo() {
      var codigo = window.crypto
        .getRandomValues(new BigUint64Array(4))
        .reduce(
          (prev, curr, index) =>
            (!index ? prev : prev.toString(36)) +
            (index % 2 ? curr.toString(36).toUpperCase() : curr.toString(36))
        )
        .split("")
        .sort(() => 128 - window.crypto.getRandomValues(new Uint8Array(1))[0])
        .join("");

      this.codigo = codigo;
    },
  },
};
</script>
