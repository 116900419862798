<template>
  <v-row no-gutters class="fill-height">
    <v-col>
      <v-data-table :headers="headers" :items="citas" class="elevation-0">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Reporte Citas</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-menu
              v-model="menuFiltro"
              offset-y
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  class="mx-1"
                >
                  Filtrar
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <v-text-field
                    v-model="fechaInicio"
                    label="Fecha inicio"
                    type="date"
                  ></v-text-field>
                  <v-text-field
                    v-model="fechaFin"
                    label="Fecha fin"
                    type="date"
                    hide-details
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-btn text outlined @click="menuFiltro = false">
                    Cerrar
                  </v-btn>
                  <v-btn color="primary" outlined text @click="superUsuario()">
                    Filtrar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="descargarPDF()"
                >
                  <v-icon> fa-solid fa-print </v-icon>
                </v-btn>
              </template>
              <span>Imprimir</span>
            </v-tooltip>
            <v-dialog v-model="dialog" max-width="800px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">Detalles de la cita</span>
                </v-card-title>

                <v-card-text>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-col cols="4">
                      <v-text-field
                        v-model="fecha_creacion"
                        label="Fecha creación"
                        readonly
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="fecha_cita"
                        label="Fecha cita"
                        readonly
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <span>Médico</span>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="nombre_medico"
                        label="Nombre"
                        outlined
                        readonly
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="correo_medico"
                        label="Correo"
                        outlined
                        readonly
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <span>Paciente</span>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="nombre_paciente"
                        label="Nombre"
                        outlined
                        readonly
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="apellido_paciente"
                        label="Apellido"
                        outlined
                        readonly
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="pasaporte_paciente"
                        label="Pasaporte"
                        outlined
                        readonly
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="n_caso_paciente"
                        label="# Caso"
                        outlined
                        readonly
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="telefono_n1_paciente"
                        label="Teléfono 1"
                        outlined
                        readonly
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="telefono_n2_paciente"
                        label="Teléfono 2"
                        outlined
                        readonly
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="telefono_n3_paciente"
                        label="Teléfono 3"
                        outlined
                        readonly
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="fecha_nacimiento"
                        label="Fecha nacimiento"
                        outlined
                        readonly
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-textarea
                        v-model="correo_paciente"
                        label="Correo "
                        outlined
                        auto-grow
                        row-height="1"
                        readonly
                        hide-details
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-textarea
                        v-model="direccion_paciente"
                        label="Dirección"
                        row-height="1"
                        auto-grow
                        outlined
                        readonly
                        hide-details
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="uid"
                        label="UID"
                        outlined
                        readonly
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Cerrar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.fecha_creacion`]="{ item }">
          <span>{{ formatoFecha(item.fecha_creacion) }}</span>
        </template>
        <template v-slot:[`item.fecha_cita`]="{ item }">
          <span>{{ formatoFecha(item.fecha_cita) }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                @click="editItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Ver</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-col>

    <v-overlay v-model="overlay">
      <lottie-player
        autoplay
        loop
        mode="normal"
        src="https://assets6.lottiefiles.com/packages/lf20_uwR49r.json"
        style="width: 200px"
      >
      </lottie-player>
    </v-overlay>
  </v-row>
</template>

<script>
import axios from "axios";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "@lottiefiles/lottie-player";

export default {
  data: () => ({
    dialog: false,
    menuFiltro: false,
    headers: [
      { text: "Fecha creación", align: "start", value: "fecha_creacion" },
      { text: "Fecha cita", value: "fecha_cita" },
      { text: "Médico", value: "nombre_medico" },
      { text: "Paciente", value: "nombre_paciente" },
      { text: "Opciones", value: "actions", sortable: false },
    ],
    citas: [],
    fechaInicio: "",
    fechaFin: "",
    objeto: [],
    //variables de la cita
    fecha_creacion: "",
    fecha_cita: "",
    nombre_medico: "",
    correo_medico: "",
    nombre_paciente: "",
    apellido_paciente: "",
    pasaporte_paciente: "",
    n_caso_paciente: "",
    telefono_n1_paciente: "",
    telefono_n2_paciente: "",
    telefono_n3_paciente: "",
    fecha_nacimiento: "",
    direccion_paciente: "",
    correo_paciente: "",
    uid: "",
    //otras variables
    overlay: false,
  }),
  methods: {
    superUsuario() {
      if (this.$store.state.usuario.super_usuario == "True") {
        this.filtrarCitasSuperUsuario();
      } else {
        this.filtrarCitas();
      }
    },
    filtrarCitasSuperUsuario() {
      if (this.fechaInicio && this.fechaFin) {
        this.overlay = true;
        this.citas = [];
        axios
          .get(
            "api/Citas/FiltrarCitasAdmin/" +
              this.fechaInicio +
              "/" +
              this.fechaFin + 
              "/" +
              this.$store.state.usuario.idUsuario
          )
          .then((response) => {
            this.overlay = false;
            this.citas = response.data;
          })
          .catch((error) => {
            this.overlay = false;
            console.log(error);
            this.alerta("Sucedio algún error", "error");
          });
      } else {
        this.alerta("Debe de seleccionar las fecha a filtrar", "info");
      }
    },
    filtrarCitas() {
      if (this.fechaInicio && this.fechaFin) {
        this.overlay = true;
        this.citas = [];
        axios
          .get(
            "api/Citas/FiltrarCitas/" +
              this.fechaInicio +
              "/" +
              this.fechaFin +
              "/" +
              this.$store.state.usuario.id_consultorio +
              "/" +
              this.$store.state.usuario.idUsuario
          )
          .then((response) => {
            this.overlay = false;
            this.citas = response.data;
          })
          .catch((error) => {
            this.overlay = false;
            console.log(error);
            this.alerta("Sucedio algún error", "error");
          });
      } else {
        this.alerta("Debe de seleccionar las fecha a filtrar", "info");
      }
    },
    editItem(item) {
      this.fecha_creacion = this.formatoFecha(item.fecha_creacion);
      this.fecha_cita = this.formatoFecha(item.fecha_cita);
      this.nombre_medico = item.nombre_medico;
      this.correo_medico = item.correo_medico;
      this.nombre_paciente = item.nombre_paciente;
      this.apellido_paciente = item.apellido_paciente;
      this.pasaporte_paciente = item.pasaporte_paciente;
      this.n_caso_paciente = item.n_caso_paciente;
      this.telefono_n1_paciente = item.telefono_n1_paciente;
      this.telefono_n2_paciente = item.telefono_n2_paciente;
      this.telefono_n3_paciente = item.telefono_n3_paciente;
      this.fecha_nacimiento = this.formatoFecha(item.fecha_nacimiento_paciente);
      this.correo_paciente = item.correo_paciente;
      this.direccion_paciente = item.direccion_paciente;
      this.uid = item.uid;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.limpiarvariables();
    },
    limpiarvariables() {
      this.fecha_creacion = "";
      this.fecha_cita = "";
      this.nombre_medico = "";
      this.correo_medico = "";
      this.nombre_paciente = "";
      this.apellido_paciente = "";
      this.pasaporte_paciente = "";
      this.n_caso_paciente = "";
      this.telefono_n1_paciente = "";
      this.telefono_n2_paciente = "";
      this.telefono_n3_paciente = "";
      this.fecha_nacimiento = "";
      this.correo_paciente = "";
      this.direccion_paciente = "";
      this.uid = "";
    },
    descargarPDF() {
      if (this.citas.length > 0) {
        this.overlay = true;
        const doc = new jsPDF("l");
        var citas = [];
        for (var i = 0; i < this.citas.length; i++) {
          citas = [];

          var a = this.objeto.findIndex((obj) => {
            return obj.id_medico == this.citas[i].id_medico;
          });
          if (a >= 0) {
            this.objeto[a].citas.push({
              id_cita: this.citas[i].id_cita,
              id_paciente: this.citas[i].id_paciente,
              fecha_creacion: this.citas[i].fecha_creacion,
              fecha_cita: this.citas[i].fecha_cita,
              nombre_paciente:
                this.citas[i].nombre_paciente +
                " " +
                this.citas[i].apellido_paciente,
              uid: this.citas[i].uid,
              pasaporte_paciente: this.citas[i].pasaporte_paciente,
              n_caso_paciente: this.citas[i].n_caso_paciente,
              telefono_n1_paciente: this.citas[i].telefono_n1_paciente,
              telefono_n2_paciente: this.citas[i].telefono_n2_paciente,
              telefono_n3_paciente: this.citas[i].telefono_n3_paciente,
              fecha_nacimiento_paciente:
                this.citas[i].fecha_nacimiento_paciente,
              direccion_paciente: this.citas[i].direccion_paciente,
              correo_paciente: this.citas[i].correo_paciente,
            });
          } else {
            citas.push({
              id_cita: this.citas[i].id_cita,
              id_paciente: this.citas[i].id_paciente,
              fecha_creacion: this.citas[i].fecha_creacion,
              fecha_cita: this.citas[i].fecha_cita,
              nombre_paciente:
                this.citas[i].nombre_paciente +
                " " +
                this.citas[i].apellido_paciente,
              uid: this.citas[i].uid,
              pasaporte_paciente: this.citas[i].pasaporte_paciente,
              n_caso_paciente: this.citas[i].n_caso_paciente,
              telefono_n1_paciente: this.citas[i].telefono_n1_paciente,
              telefono_n2_paciente: this.citas[i].telefono_n2_paciente,
              telefono_n3_paciente: this.citas[i].telefono_n3_paciente,
              fecha_nacimiento_paciente:
                this.citas[i].fecha_nacimiento_paciente,
              direccion_paciente: this.citas[i].direccion_paciente,
              correo_paciente: this.citas[i].correo_paciente,
            });
            this.objeto.push({
              id_medico: this.citas[i].id_medico,
              nombre_medico: this.citas[i].nombre_medico,
              citas: citas,
            });
          }
        }

        var startY = 25;
        var pacientes = [];
        this.objeto.forEach((element) => {
          pacientes = [];
          doc.text(element.nombre_medico, 20, startY - 5);
          element.citas.forEach((elementCita) => {
            pacientes.push({
              fecha_creacion: this.formatoFecha(elementCita.fecha_creacion),
              fecha_cita: this.formatoFecha(elementCita.fecha_cita),
              nombre_paciente: elementCita.nombre_paciente,
              uid_paciente: elementCita.uid,
              pasaporte_paciente: elementCita.pasaporte_paciente,
              n_caso_paciente: elementCita.n_caso_paciente,
              telefono_n1_paciente: elementCita.telefono_n1_paciente,
              telefono_n2_paciente: elementCita.telefono_n2_paciente,
              telefono_n3_paciente: elementCita.telefono_n3_paciente,
              fecha_nacimiento_paciente: this.formatoFecha(
                elementCita.fecha_nacimiento_paciente
              ),
              direccion_paciente: elementCita.direccion_paciente,
              correo_paciente: elementCita.correo_paciente,
            });
          });
          doc.autoTable({
            startY: startY,
            styles: { cellPadding: 1.0, fontSize: 8 },
            body: pacientes,
            columns: [
              { header: "Creación", dataKey: "fecha_creacion" },
              { header: "Cita", dataKey: "fecha_cita" },
              { header: "Paciente", dataKey: "nombre_paciente" },
              { header: "UID", dataKey: "uid_paciente" },
              { header: "Pasaporte", dataKey: "pasaporte_paciente" },
              { header: "# Caso", dataKey: "n_caso_paciente" },
              { header: "Teléfono 1", dataKey: "telefono_n1_paciente" },
              { header: "Teléfono 2", dataKey: "telefono_n2_paciente" },
              { header: "Teléfono 3", dataKey: "telefono_n3_paciente" },
              {
                header: "Fecha nacimiento",
                dataKey: "fecha_nacimiento_paciente",
              },
              { header: "Dirección", dataKey: "direccion_paciente" },
              { header: "Correo", dataKey: "correo_paciente" },
            ],
            theme: "grid",
            horizontalPageBreakRepeat: "id",
            rowPageBreak: "auto",
            bodyStyles: { valign: "top" },
          });

          startY = doc.lastAutoTable.finalY + 20;

          doc.text("Total " + pacientes.length, 250, startY - 10);
        });
        doc.save("reporte.pdf");
        this.overlay = false;
      } else {
        this.alerta("Debe de filtrar las citas", "info");
      }
    },
    formatoFecha(fecha) {
      return moment(fecha).format("DD-MM-YYYY");
    },
    alerta(texto, tipo_alerta) {
      this.$swal({
        toast: true,
        position: "top-end",
        title: texto,
        icon: tipo_alerta,
        showConfirmButton: false,
        timer: 5000,
        backdrop: false,
      });
    },
  },
};
</script>