<template>
  <v-row no-gutters class="fill-height">
    <v-card elevation="0">
      <v-card-title>
        <v-btn color="primary" @click="superUsuario()">Recargar datos</v-btn>
      </v-card-title>
      <v-card-text>
        <v-timeline align-top dense v-if="itemsBitacora.length > 0">
          <v-timeline-item
            color="primary"
            small
            v-for="(item, index) in itemsBitacora"
            :key="index"
          >
            <v-row class="pt-1">
              <v-col cols="auto">
                <strong>{{ formatoFecha(item.fecha) }}</strong>
              </v-col>
              <v-col cols="auto">
                <div>
                  <strong>Usuario</strong>
                  <p>{{ item.nombreUsuario }}</p>
                </div>
                <div>
                  <strong>Panelista</strong>
                  <p>{{ item.nombre_medico }}</p>
                </div>
                <div>
                  <strong>Fecha de la cita</strong>
                  <p>{{ item.fecha_evento }}</p>
                </div>
                <div>
                  <strong>Descripción</strong>
                  <p>{{ item.descripcion }}</p>
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
        <p v-else class="text-h5">No hay registros aún...</p>
      </v-card-text>
    </v-card>
  </v-row>
</template>
<script>
import axios from "axios";
import moment from "moment";

export default {
  data: () => ({
    //variables de cupo
    fecha_cupo: "",
    medico_cupo: "",
    n_cupos: "",
    //variables de cita
    fecha_cita: "",
    medico_cita: "",
    nombre_paciente: "",
    itemsBitacora: [],
  }),
  created() {
    this.superUsuario();
  },
  methods: {
    superUsuario() {
      if (this.$store.state.usuario.super_usuario == "True") {
        this.listarBitacoraSuperUsuario();
      } else {
        this.listarBitacora();
      }
    },
    listarBitacoraSuperUsuario() {
      axios
        .get("api/Bitacoras/Listar/" + this.$store.state.usuario.idUsuario)
        .then((response) => {
          this.itemsBitacora = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listarBitacora() {
      axios
        .get("api/Bitacoras/Listar/" + this.$store.state.usuario.idUsuario)
        .then((response) => {
          response.data.forEach((element) => {
            if (
              this.$store.state.usuario.id_consultorio == element.id_consultorio
            ) {
              this.itemsBitacora.push(element);
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatoFecha(fecha) {
      return moment(fecha).format("DD-MM-YYYY");
    },
  },
};
</script>