<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6" class="text-center mx-auto">
        <v-card width="500" flat>
          <div class="display-4 blue--text font-weight-black">OOPS!!</div>
          <div class="headline">
            Lo siento, no se pudo encontrar la página que estás buscando
          </div>
          <v-btn color="blue" dark rounded @click="irInicio()">
            <v-icon left>fa fa-arrow-left</v-icon> IR AL INICIO</v-btn
          >
        </v-card>
      </v-col>
      <v-col cols="12" md="6" class="text-center">
        <v-card width="500" flat>
          <lottie-player
            autoplay
            loop
            mode="normal"
            src="https://assets4.lottiefiles.com/packages/lf20_02epxjye.json"
            
          >
          </lottie-player>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import router from "../router";
import "@lottiefiles/lottie-player";

export default {
  data: () => ({
    //
  }),
  methods: {
    irInicio() {
      router.push({ name: "bienvenida" });
    },
  },
};
</script>