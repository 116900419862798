import Vue from 'vue'
import Vuex from 'vuex'
import decode from 'jwt-decode'
import router from '../router'
import axios from 'axios'
import accesos_clave from "../components/Accesos_clave/api"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    usuario: null,
    medico: null
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setUsuario(state, usuario) {
      state.usuario = usuario;
    },
    setMedico(state, medico) {
      state.medico = medico;
    }
  },
  actions: {
    guardarMedico({ commit }, medico) {
      localStorage.setItem("docto_visa_dr", medico);
      commit("setMedico", medico);
    },
    guardarToken({ commit }, token) {
      commit("setToken", token);
      commit("setUsuario", decode(token));
    },
    async autoLogin({ commit }) {
      var token = "";
      var token_encrip = await axios.get("api/Claves/Listar/" + localStorage.getItem("c_tuser"));
      if (token_encrip.data.length > 0) {
        token = accesos_clave.metodos.desencriptarToken(token_encrip.data[0].valor);
        if (token) {
          commit("setToken", token);
          commit("setUsuario", decode(token));

          router.push({ name: "bienvenida" }).catch(() => { });
        }
      }
    },
    salir({ commit }) {
      commit("setToken", null);
      commit("setUsuario", null);
      router.push({ name: "sesion" }).catch(() => { });
    },
  },
})
